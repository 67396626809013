import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import {Colors} from "../styles/Colors";

export const useStyles = makeStyles(() => ({


    container: {
        display: "flex",
        flex: 1,
        color: Colors.PlaceHolder,
        fontSize: '14px',
        fontWeight: 400,
        lineHeight: '20px',
        width: '100%',
        marginTop: 27
    }
}))


const InfoLayout: React.FC = ({children}) => {

    const classes = useStyles()

    return <div className={classes.container}>{children}</div>
}

export default InfoLayout