import React from "react";
import {useSelector} from "react-redux";
import {ReduxState} from "../../store/types";
import Loader from "../Loader/Loader";
import {CallsStateType} from "../../store/reducers/calls/reducer";
import {ExtendTextTranscriptionInterface, useStyle} from "./ExtendTextTranscription.utils";
import {useTranslation} from "react-i18next";
import TranscriptionAction from "./TranscriptionAction";
import TextSearchBar from "../../layouts/TextSearchBar";


const ExtendTextTranscription: React.VFC<ExtendTextTranscriptionInterface> = (
    {
        detailsVisible,
        callRecordingId,
        formatedPeriod,
        iXdr
    }) => {

    const classes = useStyle();
    const {t} = useTranslation()

    const {transcription, fetchTranscription} = useSelector<ReduxState, CallsStateType>(
        (state) => state.calls
    );


    if (fetchTranscription) {
        return (
            <div style={{display: "flex", alignSelf: 'flex-start', alignContent: 'center'}}>
                <Loader dataQa={'fetch-transcription'} text={t('common:processing') + '...'}
                        customClass={classes.loading}/>
            </div>
        )
    }


    return detailsVisible ? (


        <div className={classes.transcriptionContainer}>

            <TextSearchBar
                formatedPeriod={formatedPeriod}
                transcription={transcription}
                callRecordingId={callRecordingId}
                actionContainerCustomClass={classes.actionContainer}
                rowContainerDetailsCustomClass={classes.transcriptionContainerDetails}
            >
                {callRecordingId && (
                    <TranscriptionAction callRecordingId={callRecordingId}/>
                )}
            </TextSearchBar>

        </div>
    ) : <></>

}

export default ExtendTextTranscription