import React, {useCallback, useEffect, useMemo, useState} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {actions} from "../../../store";
import {Permission} from "../../../store/types/Permission";
import DataGrid from "../../DataGrid/DataGrid";
import {PaginationMode} from "../../DataGrid/types";
import PermissionProvider from "../../PermissionProvider/PermissionProvider";
import {generateColumns, useStyles} from "./AgentsTab.utils";
import {SipCallType, UpdatedRingGroupMember} from "../../../store/actions/ringgroups/payloads";
import {YesNo} from "../../../store/types/CallScreening";
import FiltersComponent, {AgentListFilters} from "./FiltersComponent";
import {SearchCallback} from "../../../views/CallHistory/CallHistory.utils";
import { getRoute, Routes } from "../../../routes/routes";
import { AgentsTypeData, getStatus } from "../../../hooks/agents/useAgentsHook";
import { ReduxState } from "../../../store/types";
import { getDispatchType } from "../../../utils/extensions/AddExtensionToRingGroup";
import { DispatchType } from "../../RingGroups/groupDialogUtils";
import {v4 as uuidv4} from "uuid";
import { defaultAccountListFetchCount } from "../../../store/sagas/agents/saga";

const AgentsTab: React.VFC<SearchCallback>  = ({callback}) => {
    const {t} = useTranslation()
    const dispatch = useDispatch();
    const classes = useStyles();
    const [filters,setFilters] = useState<AgentListFilters>({})
    const [forceFirstPage, setForceFirstPage] = useState(false)

    const {
        accounts,
        huntGroupList,
        isLoading,
        agents,
        agentsCache
    } = useSelector((state: ReduxState) => state.agents);

    const {sipCallsList, isLoadingSipCalls} = useSelector((state: ReduxState) => state.ringgroups);

    const data = useMemo(() => {
        let _data: AgentsTypeData[] = [];

        huntGroupList?.huntgroup_list.forEach(group => {
            _data.push({
                id: group.name,
                custom_id: group.name,
                groupName: group.name,
                isGroup: true,
                link: getRoute(Routes.RingGroupsDetails, {id: group.i_c_group}),
                i_c_group: group.i_c_group,
                showIcon: false
            })

            if (group.assigned_extensions) {
                const temp = [...group.assigned_extensions]
                    .filter(ext => ext.type == 'Account');

                temp.forEach(extensionItem => {
                    const dispatchType = extensionItem.hunt_active === 'Y'
                        ? getDispatchType(extensionItem.hunt_delay, extensionItem.hunt_expire)
                        : DispatchType.Disabled

                    const account = accounts?.account_list.find(
                        acc => acc.extension_id == extensionItem.id
                    );

                    //@ts-ignore
                    const status = getStatus(account, sipCallsList ?? []);

                    _data.push({
                        groupName: group.name,
                        isGroup: false,
                        id: uuidv4(),
                        custom_id: uuidv4(),
                        extension: extensionItem.id || '',
                        name: extensionItem.name || '',
                        duration: 'some duration',
                        isLogged: dispatchType !== DispatchType.Disabled,
                        // @ts-ignore
                        status: status,
                        updatedRingGroupMember: {
                            i_c_group: group.i_c_group,
                            i_ringback_tone: group.i_ringback_tone,
                            extension: extensionItem
                        },
                        showIcon: status.callType ? [SipCallType.Incoming, SipCallType.Outgoing].includes(status.callType) : false
                    })
                })
            }
        });
        const filterTemp = [..._data];
        _data = _data.map(item => {
            if (item.isGroup) {
                item.show = !!filterTemp.find(member => !member.isGroup && member.groupName == item.groupName);
            } else {
                item.show = true
            }
            return item;
        });
    
        _data = _data.filter(item => item.show);
        return _data;
    }, [huntGroupList, accounts, sipCallsList, agents]);

    useEffect(() => {
        if(!isLoading && accounts && accounts.total > defaultAccountListFetchCount && 
            accounts.account_list?.length === defaultAccountListFetchCount)
        {
            dispatch(actions.fetchRestOfAccountsForAgentTab.request());
        }
    }, [accounts, isLoading]);

    const fetchData = (payload?: AgentListFilters) => {

        let tempFilters = filters ? {...filters}: undefined
        // @ts-ignore
        if(tempFilters && tempFilters?.agentsFilter && tempFilters.agentsFilter.find(item => item.id === -1))
        {
            tempFilters = {...tempFilters, agentsFilter:[]}
        }

        dispatch(actions.getAgentsTabData.request({...tempFilters || (payload ?? {}), isInit: payload?.isInit?? false}));
        dispatch(actions.getSipCallsList.request({}));
        setForceFirstPage(true)
        callback && callback();
    }

    const columns = useMemo(
        () =>
            generateColumns(
                t,
                (obj: UpdatedRingGroupMember) => {
                    dispatch(actions.updateRingGroupMember.request({
                        ...obj,
                        extension: {
                            ...obj.extension,
                            hunt_active: obj.extension?.hunt_active == YesNo.Yes ? YesNo.No : YesNo.Yes
                        }
                    }))
                    fetchData({...filters})
                },
                classes
            ),
        [data],
    );

    useEffect(() => {
        fetchData({isInit: true})
    }, []);

    const rowCount = useCallback(()=>{
        return data.filter(el => !el.isGroup).length
    },[data])

    return (
        <div style={{maxWidth:1061}}>
            <PermissionProvider permission={Permission.SupervisorPanel.Agents.value}>

                <FiltersComponent
                    handleSearch={fetchData}
                    agentsList={agents}
                    sourceAgentList={agentsCache}
                    setFilter={(data)=> (setFilters({...filters, ...data}))}
                />

                <DataGrid
                    columns={columns}
                    data={data}
                    loading={isLoading || isLoadingSipCalls}
                    paginationMode={PaginationMode.Client}
                    onPageChange={()=>setForceFirstPage(false)}
                    onPageSizeChange={()=>setForceFirstPage(false)}
                    rowCount={rowCount()}
                    centeredRows
                    initialPageSize={10}
                    classes={{tableContainer:classes.tableContainer}}
                    forceFirstPage={forceFirstPage}
                />

            </PermissionProvider>
        </div>

    )
}

export default AgentsTab