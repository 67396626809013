import {ActionType, createReducer} from 'typesafe-actions';
import {actions} from '../../index';
import {AccountListResponse} from "../../types/Account";
import {GetHuntGroupListResponse} from "../../actions/ringgroups/payloads";
import {AssignedExtension} from "../../types/RingGroup";

export interface AgentsStateType {
    isLoading?: boolean;
    accounts?: AccountListResponse;
    huntGroupList?: GetHuntGroupListResponse,
    agents?: AssignedExtension[],
    agentsCache?:AssignedExtension[],
    participantId?:string
}

export type AgentsActionsType = ActionType<typeof actions>;

export const initialState: AgentsStateType = {
    isLoading: false
};

const agentsReducer = createReducer<
    AgentsStateType,
    AgentsActionsType
>(initialState)
    .handleAction(actions.getAgentsTabData.request, (state) => ({
        ...state,
        isLoading: true,
    }))
    .handleAction(actions.getAgentsTabData.success, (state, action) => ({
        ...state,
        isLoading: false,
        accounts: action.payload.accounts,
        huntGroupList: action.payload.huntGroupList,
        agents: action.payload.agents
    }))
    .handleAction(actions.getAgentsTabData.failure, (state) => ({
        ...state,
        isLoading: false,
    }))
    .handleAction(actions.cacheAgents, (state,action) => ({
        ...state,
        agentsCache: action.payload.agents
    }))
    .handleAction(actions.participantId, (state,action) => ({
        ...state,
        participantId: action.payload.id
    }))
    .handleAction(actions.fetchRestOfAccountsForAgentTab.success, (state, action) => ({
        ...state,
        accounts: action.payload.accounts,
    }))
;

export default agentsReducer;
