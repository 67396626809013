import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {AudioPlayerProps, formatDuration, getVolumeInitialValue, useStyles} from './InternalAudioPlayer.utils';
import ReactPlayer from 'react-player';
import {ClickAwayListener, Popper, Slider} from '@material-ui/core';
import {SkipNext, SkipPrevious, VolumeDown, VolumeOff, VolumeUp} from '@material-ui/icons';
import {useTranslation} from 'react-i18next';
import classNames from 'classnames';
import {OnProgressProps} from 'react-player/base';
import IconButton from '../IconButton/IconButton';
import CustomizedIconButton from '../IconButton/IconButton';
import {useDispatch, useSelector} from 'react-redux';
import {
    initialState,
    PlayingFile,
    RecordingsPlayingState,
    RecordingsPlayingStatus
} from '../../store/reducers/calls/reducer';
import {ReduxState} from '../../store/types';
import {actions} from '../../store';
import {xdrToId} from "../../utils/transformers";
import ExtendTextTranscription from "../TextTranscription/ExtendTextTranscription";
import InternalPlayFileButton from "../PlayFileButton/InternalPlayFileButton";
import InfoLayout from "../../layouts/InfoLayout";

const InternalAudioPlayer: React.FC<AudioPlayerProps> = (
    {
        dataQa,
        dataTestId,
        children
    }) => {

    const zeroTimeText = '0:00';

    const classes = useStyles();
    const {t} = useTranslation();
    const dispatch = useDispatch();

    const ref = useRef<ReactPlayer>(null);
    const buttonRef = useRef(null);
    const popperRef = useRef(null);
    const mainContainerRef = useRef(null)

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const ids = open ? 'simple-popper' : undefined;

    const [duration, setDuration] = useState<string>('--:--');
    const [durationValue, setDurationValue] = useState<number>(0);
    const [progress, setProgress] = useState<number>(0);
    const [currentTime, setCurrentTime] = useState<string>(zeroTimeText);
    const [volume, setVolume] = useState<number>(getVolumeInitialValue());

    const recordingsPlayer: RecordingsPlayingState = useSelector<ReduxState, RecordingsPlayingState>(
        (state) => state.calls.internalRecordingsPlayer,
    );

    const downloading = useMemo(() => {
        return recordingsPlayer &&
            (recordingsPlayer.status == RecordingsPlayingStatus.downloading
                || recordingsPlayer.status == RecordingsPlayingStatus.downloadedNotStartedPlaying);
    }, [recordingsPlayer]);

    const playing = useMemo(() => {
        return recordingsPlayer && (recordingsPlayer.status === RecordingsPlayingStatus.playing
            || recordingsPlayer.status === RecordingsPlayingStatus.downloadedNotStartedPlaying);
    }, [recordingsPlayer]);

    const setPlayStarted = useCallback(() => {
        dispatch(actions.internalUpdateRecordingsPlayingState({
            ...recordingsPlayer,
            status: RecordingsPlayingStatus.playing
        }));
    }, [recordingsPlayer]);

    const currentFileSet = useMemo(() => {
        if (!(recordingsPlayer?.filesQueue) || !(recordingsPlayer?.currentFile)) {
            return [];
        }

        for (const sets of recordingsPlayer.filesQueue) {
            for (const f of sets) {
                if (f.call_recording_id === recordingsPlayer.currentFile.call_recording_id &&
                    f.i_xdr === recordingsPlayer.currentFile.i_xdr) {
                    return sets;
                }
            }
        }
        return [];
    }, [recordingsPlayer]);

    const currentFileIndex = useMemo(() => {
        if (!recordingsPlayer || !(recordingsPlayer?.filesQueue) || !(recordingsPlayer?.currentFile)) {
            return 0;
        }
        if (currentFileSet.length > 1) {
            const pos = currentFileSet.findIndex(f => (f.call_recording_id === recordingsPlayer.currentFile?.call_recording_id) &&
                (f.i_xdr === recordingsPlayer.currentFile?.i_xdr));
            return pos;
        }
        return 0;
    }, [currentFileSet, recordingsPlayer]);

    const nextFileToPlay = useCallback(() => {
        let file: PlayingFile | null = null;
        if (!recordingsPlayer.filesQueue || !recordingsPlayer.currentFile) {
            return file;
        }
        const allFiles = recordingsPlayer.filesQueue
            .reduce(function (a, b) {
                return a.concat(b);
            }, [])
            .filter(e => e);
        const indexInAllFiles = allFiles.findIndex(e =>
            e.call_recording_id === recordingsPlayer.currentFile?.call_recording_id
            && e.i_xdr === recordingsPlayer.currentFile?.i_xdr);
        if (indexInAllFiles >= 0 && indexInAllFiles < allFiles.length - 1) {
            file = allFiles[indexInAllFiles + 1];
        }
        return file;
    }, [recordingsPlayer]);

    const previousFileToPlay = useCallback(() => {
        let file: PlayingFile | null = null;
        if (!recordingsPlayer.filesQueue || !recordingsPlayer.currentFile) {
            return file;
        }
        const allFiles = recordingsPlayer.filesQueue
            .reduce(function (a, b) {
                return a.concat(b);
            }, [])
            .filter(e => e);
        const indexInAllFiles = allFiles.findIndex(e =>
            e.call_recording_id === recordingsPlayer.currentFile?.call_recording_id
            && e.i_xdr === recordingsPlayer.currentFile?.i_xdr);
        if (indexInAllFiles > 0) {
            file = allFiles[indexInAllFiles - 1];
        }
        return file;
    }, [recordingsPlayer]);

    const playNextEnabled = useMemo(() => {
        return nextFileToPlay() !== null;
    }, [currentFileSet, recordingsPlayer, currentFileIndex]);

    const playPreviousEnabled = useMemo(() => {
        return previousFileToPlay() !== null;
    }, [currentFileSet, recordingsPlayer, currentFileIndex]);


    const checkFileExist = () => {
        const callRecordingId = currentFileSet.length ?
            xdrToId(currentFileSet[currentFileIndex].call_recording_id) : undefined
        if (callRecordingId) {
            callRecordingId && dispatch(actions.checkIfTranscriptionExist.request({
                callRecordingId: callRecordingId,
                autoFetch: true
            }))
        }
    }

    useEffect(() => {
        if (recordingsPlayer && (recordingsPlayer.status === RecordingsPlayingStatus.downloading
            || recordingsPlayer.status === RecordingsPlayingStatus.downloadedNotStartedPlaying
            || recordingsPlayer.status === RecordingsPlayingStatus.finished)
        ) {
            setProgress(0);
            if (recordingsPlayer.status !== RecordingsPlayingStatus.finished) {
                setDuration('--:--');
            }
            setCurrentTime(zeroTimeText);
        }
    }, [recordingsPlayer]);

    const navigateToTheNextFile = useCallback((next: boolean) => {
        if ((playNextEnabled && next) || (playPreviousEnabled && !next)) {
            const file = next ? nextFileToPlay() : previousFileToPlay();
            if (file) {
                dispatch(actions.internalUpdateRecordingsPlayingState({
                    ...recordingsPlayer,
                    status: RecordingsPlayingStatus.downloading,
                    currentFile: file,
                    currentBlob: null
                }));

                dispatch(
                    actions.getCallHistoryFileAsBlob.request({
                        i_xdr: file.i_xdr,
                        call_recording_id: file.call_recording_id,
                        callback: (url) => {
                            setTimeout(() => {
                                dispatch(actions.internalUpdateRecordingsPlayingState({
                                    ...recordingsPlayer,
                                    currentFile: file,
                                    status: RecordingsPlayingStatus.downloadedNotStartedPlaying,
                                    currentBlob: url
                                }));
                            }, 1);
                        },
                        restrictedCallback: () => {
                            setTimeout(() => {
                                dispatch(actions.internalUpdateRecordingsPlayingState({
                                    ...initialState.internalRecordingsPlayer,
                                    status: RecordingsPlayingStatus.dowloadingRestricted,
                                }));
                            }, 1);
                        }
                    }),
                );

            } else {
                dispatch(actions.internalUpdateRecordingsPlayingState({
                    ...recordingsPlayer,
                    status: RecordingsPlayingStatus.downloadedNotStartedPlaying
                }));
            }
        } else {
            dispatch(actions.internalUpdateRecordingsPlayingState({
                ...initialState.internalRecordingsPlayer,
                filesQueue: recordingsPlayer.filesQueue
            }));
        }
    }, [playNextEnabled, recordingsPlayer, currentFileSet]);

    useEffect(() => {
        checkFileExist()
    }, [currentFileSet, currentFileIndex]);

    const onFilePlayingFinished = useCallback(() => {
        if (currentFileSet.length > 1 && currentFileIndex < (currentFileSet.length - 1) && playNextEnabled) {
            navigateToTheNextFile(true);
        } else {
            setTimeout(() => {
                dispatch(actions.internalUpdateRecordingsPlayingState({
                    ...recordingsPlayer,
                    status: RecordingsPlayingStatus.finished
                }));
            }, 100);
        }
    }, [recordingsPlayer, currentFileSet, currentFileIndex, playNextEnabled]);

    const handleClickVolume = useCallback((event) => {
        setAnchorEl(anchorEl ? null : event.currentTarget);
    }, [anchorEl]);

    const setVolumeCallback = useCallback((value: number) => {
        const v = value?.toFixed(2) || '1.00';
        localStorage.setItem('audio_volume', v);
        setVolume(value);
    }, [setVolume]);

    const isTranscriptionAvailable = useSelector<ReduxState, boolean>(
        (state) => state.calls.isTranscriptionAvailable,
    );

    const isTranscriptionDownloading = useSelector<ReduxState, boolean>(
        (state) => state.calls.isTranscriptionDownloading,
    );

    const noTranscription = useMemo(() => {
        return !isTranscriptionAvailable || isTranscriptionDownloading
    }, [isTranscriptionAvailable, isTranscriptionDownloading])

    const seconds = progress * durationValue;
    const duration2 = Math.floor(seconds ?? 0);
    const formatted = formatDuration(duration2, zeroTimeText);

    return (
        <div
            ref={mainContainerRef}
            className={classNames(classes.rootContainer)}
            data-qa={dataQa}
            data-testid={dataTestId}
        >
            <div
                className={classes.playerContainerDiv}
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    width: '100%'
                }}
            >
                <CustomizedIconButton
                    onClick={() => {
                        navigateToTheNextFile?.(false);
                    }}
                    className={classNames(classes.playNextPrevButtonContainer)}
                    disabled={!playPreviousEnabled}
                    dataTestId={'button-previous-file-icon'}
                    skipPermission
                >
                    <SkipPrevious className={classes.playNextPrevButton}/>
                </CustomizedIconButton>

                <div className={classNames(classes.playButtonContainer,
                    downloading && classes.playButtonContainerPadding,
                    !downloading && classes.playButtonContainerPaddingNotDownloading)}>
                    <InternalPlayFileButton
                        files={currentFileSet}
                        customClasses={{
                            beforePlaying: classes.playPauseButtonLoading,
                            container: classes.playPauseButtonBase
                        }}
                        dataTestId={'button-play-pause-icon'}
                        skipPermission
                    />
                </div>

                <IconButton
                    onClick={() => {
                        navigateToTheNextFile?.(true);
                    }}
                    className={classNames(classes.playNextPrevButtonContainer)}
                    disabled={!playNextEnabled}
                    dataTestId={'button-next-file-icon'}
                    skipPermission
                >
                    <SkipNext className={classes.playNextPrevButton}/>
                </IconButton>

                <div className={classNames(classes.durationLabelBase, classes.durationLabelLeft)}>
                    <span>{currentTime}</span>
                </div>

                <div className={classes.sliderContainer}>
                    <Slider aria-label="progress" min={0} max={1} value={progress} step={0.01}
                            className={classes.progressSlider}
                            data-testid={'audio-slider-progress'}
                            onChange={(_, v) => {
                                const val: number = (v as number) ?? 0;
                                const seconds = val * durationValue;
                                const duration = Math.floor(seconds ?? 0);
                                const formatted = formatDuration(duration, zeroTimeText);
                                setCurrentTime(formatted);
                                setProgress(val);
                                ref?.current?.seekTo(duration, 'seconds');
                            }}
                    />
                </div>
                <div className={classNames(classes.durationLabelBase, classes.durationLabelRight)}>
                    <span>{duration}</span>
                </div>
                <CustomizedIconButton
                    onClick={handleClickVolume}
                    dataTestId="volume-popper"
                    className={classNames(classes.volumeContainer)}
                    skipPermission>
                    <div ref={buttonRef} style={{height: 24}}>
                        {volume === 0 ? (
                            <VolumeOff className={classes.playNextPrevButton}/>
                        ) : (
                            volume <= 0.5 ? (
                                <VolumeDown className={classes.playNextPrevButton}/>
                            ) : (
                                <VolumeUp className={classes.playNextPrevButton}/>
                            )
                        )}
                    </div>
                </CustomizedIconButton>
                <Popper
                    open={open}
                    anchorEl={buttonRef.current}
                    container={document.body}
                    id={ids}
                    style={{zIndex: 1000}}
                    popperOptions={{
                        modifiers: {
                            offset: {
                                enabled: true,
                                offset: '0,8px',
                            },
                        },
                        positionFixed: true,
                    }}
                    placement="top"
                >
                    {({TransitionProps}) => (
                        <ClickAwayListener onClickAway={handleClickVolume}>
                            <div className={classes.popupVolumeContainer} ref={popperRef}
                                 {...TransitionProps}>
                                <Slider aria-label="volume" min={0} max={1} value={volume} step={0.01}
                                        orientation="vertical"
                                        data-testid={'audio-slider-volume'}
                                        className={classes.volumeSlider}
                                        onChange={(_, v) => {
                                            const val: number = (v as number) ?? 0;
                                            setVolumeCallback(val);
                                        }}
                                />
                            </div>
                        </ClickAwayListener>
                    )}
                </Popper>
                <div className={classes.additionalTextContainer}>
                    {(currentFileSet.length > 1) && (
                        <span>
                    {t('screens:callSettings.fileOutOf', {
                        current: currentFileIndex + 1,
                        total: currentFileSet.length
                    })}
                </span>)}
                </div>
                <div className={classes.playerContainer}>
                    <ReactPlayer
                        url={recordingsPlayer?.currentBlob ?? undefined}
                        ref={ref}
                        playing={playing}
                        onPlay={() => {
                            setPlayStarted();
                        }}
                        onEnded={onFilePlayingFinished}
                        onDuration={(duration: number) => {
                            setDuration(formatDuration(duration, '--:--'));
                            setDurationValue(duration);
                        }}
                        progressInterval={100}
                        onProgress={(state: OnProgressProps) => {
                            const duration = Math.floor(state?.playedSeconds ?? 0);
                            const formatted = formatDuration(duration, zeroTimeText);
                            setCurrentTime(formatted);
                            const playedPercentage = (state?.playedSeconds ?? 0) / (state?.loadedSeconds ?? 0);
                            setProgress(playedPercentage);
                        }}
                        volume={volume}
                    />
                </div>
                    {children}
            </div>

            <ExtendTextTranscription
                iXdr={currentFileSet && currentFileIndex && currentFileSet[currentFileIndex].i_xdr}
                formatedPeriod={formatted}
                detailsVisible={isTranscriptionAvailable}
                callRecordingId={!noTranscription && currentFileSet.length ? xdrToId(currentFileSet[currentFileIndex].call_recording_id) : undefined}
            />

            {!isTranscriptionAvailable && <div className={classes.border}></div>}

            {!isTranscriptionAvailable && <InfoLayout>{t('screens:calls.noTranscription')}</InfoLayout>}

        </div>
    );
};

export default InternalAudioPlayer;
