import {all, call, put, select, takeLatest} from 'redux-saga/effects';
import * as actions from '../../actions';
import {api} from '../../services/axios';
import {AxiosError, AxiosResponse} from 'axios';
import {APIErrorInterface, ReduxState} from '../../types';
import JSONFormData from '../../../utils/JSONFormData';
import {ActionType} from 'typesafe-actions';
import {ActiveCall, CallHistory} from '../../types/CallHistory';
import {fetchAccountList, getGlobalCurrencyData, getGlobalCustomerInfo, getSessionData,} from '../generic/saga';
import {CustomerInfo} from '../../types/CustomerInfo';
import fileDownload from 'js-file-download';
import toast from 'react-hot-toast';
import i18n from '../../../services/i18n';
import {showErrorToast} from '../../../utils/showErrorToast';
import {Account, BillingSession, CDR, Customer,} from '../../../services/endpoints';
import dayjs from '../../../services/customDayJs';
import {convertUserLocalTimeToUtc} from '../../../utils/dateWithTimezoneConversion';
import {WidgetCallHistory} from '../../types/Wallboard';
import {AccountListResponse} from '../../types/Account';
import {ExtensionType} from '../../types/Extension';
import {SipCall} from '../../actions/ringgroups/payloads';
import {pagedAsyncDataRequest, pagedDataRequest} from '../paged.data.saga';
import {download, downloadToTxt} from "../../../utils/downloadFileFromJson";
import { getMyProfileDetails } from '../myProfile/saga';
import {callApiGetSipCallsList} from '../ringgroups/saga';
import {Segment, TranscriptionData} from "../../reducers/calls/reducer";

const dateFormat = 'YYYY-MM-DD HH:mm:ss';

export function* getExtensionCallHistoryList(
    action: ActionType<typeof actions.getExtensionCallHistory.request>,
) {
    try {
        const {session_id, csrf_token} = yield select((state: ReduxState) => state.auth);
        const body = new JSONFormData(session_id, csrf_token);

        if (action.payload.withInitCalls) {
            yield getGlobalCustomerInfo();
            const customerInfoRes: AxiosResponse<CustomerInfo> = yield api.post(
                Customer.GetCustomerInfo,
                body,
            );
            yield put(
                actions.setCustomerCurrencyForCallHistory({
                    currency: customerInfoRes.data.customer_info.iso_4217,
                }),
            );
            yield getSessionData();
        }

        body.setParams({
            i_account: action.payload.i_account,
            from_date: action.payload.from,
            to_date: action.payload.till,
            i_service_type: 3,
            cli: action.payload.cli || undefined,
            cld: action.payload.cld || undefined,
            offset: action.payload.offset,
            limit: action.payload.limit,
            get_total: 1,
            with_cr_download_ids: '1',
            show_unsuccessful: action.payload.unsuccessfulCalls
                ? '1'
                : undefined,
            call_recording: action.payload.isCallRecording ? '1' : undefined,
        });

        const res: AxiosResponse<{
            xdr_list: CallHistory[];
            total: number;
        }> = yield api.post(Account.GetXdrList, body);

        yield put(actions.getExtensionCallHistory.success(res.data));
    } catch (err) {
        yield put(actions.getExtensionCallHistory.failure());
    }
}


export function extractExtensionName(input: string []): string {
    let ext = 'wav'

    input.forEach((item: string) => {
        if (item.indexOf('filename') != -1) {
            ext = item.substring(item.indexOf('.') + 1);
            ext = ext.slice(0, ext.length - 1);
        }
    })

    return ext;
}

export function* getExtensionCallHistoryFile(
    action: ActionType<typeof actions.getExtensionCallHistoryFile.request>,
) {
    try {
        const {session_id, csrf_token} = yield select((state: ReduxState) => state.auth);
        const body = new JSONFormData(session_id, csrf_token);

        body.setParams({...action.payload, call_recording_id: undefined});

        const res: AxiosResponse<string> = yield api.post(
            CDR.GetCallRecording,
            body,
            {responseType: 'blob'},
        );
        const contentDisposition: [] = res.headers["content-disposition"] ? res.headers["content-disposition"].split(" ") : [];
        const ext = extractExtensionName(contentDisposition);

        fileDownload(res.data, `${action.payload.fileName || 'audio'}.${ext}`);
        action.payload.callback && action.payload.callback();
    } catch (e) {
        if ((e as AxiosError)?.isAxiosError) {
            const data = (e as AxiosError)?.response?.data;
            if (data) {
                const isJsonBlob = data instanceof Blob && data.type === "application/json";
                //@ts-ignore
                const responseData = isJsonBlob ? (yield data?.text()) : data || {};
                const responseJson = (typeof responseData === "string") ? JSON.parse(responseData) : responseData;
                const err = responseJson as APIErrorInterface;
                if (err?.faultcode?.endsWith('.access_denied')) {
                    toast.dismiss();
                    showErrorToast(i18n.t<string>('errors:common.noPermissionsToData'));
                }
            }
        }
        yield put(actions.getExtensionCallHistoryFile.failure());
    }
}

export function* getCallHistoryList(
    action: ActionType<typeof actions.getCallHistory.request>,
) {
    try {
        const {session_id, csrf_token} = yield select((state: ReduxState) => state.auth);
        const body = new JSONFormData(session_id, csrf_token);

        if (action.payload.withInitCalls) {
            yield getGlobalCustomerInfo();

            const customerInfoRes: AxiosResponse<CustomerInfo> = yield api.post(
                Customer.GetCustomerInfo,
                body,
            );
            yield put(
                actions.setCustomerCurrencyForCallHistory({
                    currency: customerInfoRes.data.customer_info.iso_4217,
                }),
            );

            yield getSessionData();
            yield getGlobalCurrencyData();
        }

        body.setParams({
            from_date: action.payload.from,
            to_date: action.payload.till,
            i_service_type: 3,
            cli: action.payload.cli || undefined,
            cld: action.payload.cld || undefined,
            offset: action.payload.offset,
            limit: action.payload.limit,
            get_total: 1,
            with_cr_download_ids: '1',
            show_unsuccessful: action.payload.unsuccessfulCalls
                ? '1'
                : undefined,
            call_recording: action.payload.callRecordingsOnly ? '1' : undefined,
        });

        const res: AxiosResponse<{
            xdr_list: CallHistory[];
            total: number;
        }> = yield api.post(Customer.GetCustomerXDRS, body);
        const xdr = {...res.data};
        xdr.xdr_list = res.data.xdr_list.map((e, index) => {
            return {
                ...e,
                id: index,
            };
        });
        yield put(actions.getCallHistory.success(xdr));
    } catch (err) {
        yield put(actions.getCallHistory.failure());
    }
}

export function* removeCallRecordings(
    action: ActionType<typeof actions.removeCallRecordings.request>,
) {
    try {
        const {session_id, csrf_token} = yield select((state: ReduxState) => state.auth);
        const body = new JSONFormData(session_id, csrf_token);

        body.setParams({
            xdr_list: action.payload.items.map((v) => ({
                id: v.i_xdr,
                call_recording_id: v.cr_download_ids?.[0],
            })),
        });

        yield api.post(CDR.BatchDeleteCallRecording, body);

        if (action.payload.getDataExtensionRequestPayload) {
            yield put(
                actions.getExtensionCallHistory.request(
                    action.payload.getDataExtensionRequestPayload,
                ),
            );
        }

        if (action.payload.getDataRequestPayload) {
            yield put(
                actions.getCallHistory.request(
                    action.payload.getDataRequestPayload,
                ),
            );
        }

        showErrorToast(i18n.t<string>('screens:callSettings.callRecordingsRemoved'));

        yield put(actions.removeCallRecordings.success());
    } catch (err: any) {
        showErrorToast(err.response?.data?.faultstring);
        yield put(actions.removeCallRecordings.failure());
    }
}

export function* getCallHistoryBasicData(
    action: ActionType<typeof actions.getCallHistoryBasicData.request>,
) {

    try {

        const {session_id, csrf_token} = yield select((state: ReduxState) => state.auth);
        // @ts-ignore
        const timezoneOffset = yield select((state: ReduxState) => state.generic.sessionData?.tz_offset) || 0;

        const body = new JSONFormData(session_id, csrf_token);

        const params = {
            show_unsuccessful: '0',
            i_service_type: '3',
            get_total: '1',
            limit: '1',
        };

        const date = dayjs.utc();

        const todayFromDate = date.utcOffset(timezoneOffset / 60).startOf('date').format(dateFormat);
        const todayToDate = date.utcOffset(timezoneOffset / 60).format(dateFormat);

        body.setParams({
            ...params,
            from_date: convertUserLocalTimeToUtc(todayFromDate, timezoneOffset),
            to_date: convertUserLocalTimeToUtc(todayToDate, timezoneOffset),
        });

        const todayResponse: AxiosResponse<{
            total: number;
        }> = yield api.post(Customer.GetCustomerXDRS, body);

        const yesterdayCallsFromDate = date
            .subtract(1, 'day')
            .utcOffset(timezoneOffset / 60)
            .startOf('date')
            .format(dateFormat);

        const yesterdayCallsToDate = date
            .subtract(1, 'day')
            .utcOffset(timezoneOffset / 60)
            .format(dateFormat);

        body.setParams({
            ...params,
            from_date: convertUserLocalTimeToUtc(yesterdayCallsFromDate, timezoneOffset),
            to_date: convertUserLocalTimeToUtc(yesterdayCallsToDate, timezoneOffset),
        });

        const yesterdayResponse: AxiosResponse<{
            total: number;
        }> = yield api.post(Customer.GetCustomerXDRS, body);

        yield put(
            actions.getCallHistoryBasicData.success({
                todayCalls: todayResponse.data.total,
                yesterdayCalls: yesterdayResponse.data.total,
            }),
        );

        yield all([
            call(getExtensionsListForCallsDoughnut, {
                payload: {skipDid: true, skipService: true, limit: 100},
                type: 'GET_EXTENSIONS_FOR_CALLS_DOUGHNUT'
            })
        ]);

        if (action.payload.updateSipCalls) {
            const data: SipCall[] = yield call(callApiGetSipCallsList, {
                payload: {},
                type: 'GET_SIP_CALLS_LIST'
            });
            yield put(actions.getSipCallsList.success(data));
        }
    } catch (err) {
        yield put(actions.getCallHistoryBasicData.failure());
    }
}

export function* getSupervisorHeaderData() {

    try {
        yield all([
            call(getExtensionsListForCallsDoughnut, {
                payload: {skipDid: false, skipService: false},
                type: 'GET_EXTENSIONS_FOR_CALLS_DOUGHNUT'
            })
        ]);

        const data: SipCall[] = yield call(callApiGetSipCallsList, {
            payload: {},
            type: 'GET_SIP_CALLS_LIST'
        });
        yield put(actions.getSipCallsList.success(data));

        yield put(actions.getSupervisorHeaderData.success())

    } catch (e) {
        yield put(actions.getSupervisorHeaderData.failure())
    }

}

export function* getInitialCallHistoryBasicData() {
    try {
        yield put(actions.getInitialCallHistoryBasicData.success());

    } catch (e) {
        yield put(actions.getInitialCallHistoryBasicData.failure());
    }
}


export function* getActiveCallsRequest(serviceType?: number) {
    const param1 = {
        i_service_type: serviceType ?? undefined
    };
    const firstReqItems: ActiveCall[] = yield pagedDataRequest<ActiveCall>(BillingSession.GetActiveSessionsList, param1, (data) => data.active_session_list);

    return {
        data: {
            active_session_list: firstReqItems,
        }
    };
}

export function* getCallHistoryFileAsBlob(
    action: ActionType<typeof actions.getCallHistoryFileAsBlob.request>,
) {
    try {
        const {session_id, csrf_token} = yield select((state: ReduxState) => state.auth);
        const body = new JSONFormData(session_id, csrf_token);

        body.setParams({...action.payload});

        const res: AxiosResponse<Blob> = yield api.post(
            CDR.GetCallRecording,
            body,
            {responseType: 'blob'},
        );

        const blob = res.data as Blob;
        const objectUrl = URL.createObjectURL(blob);

        action.payload.callback && action.payload.callback(objectUrl);

        yield put(actions.getCallHistoryFileAsBlob.success());
    } catch (e) {
        if ((e as AxiosError)?.isAxiosError) {
            const data = (e as AxiosError)?.response?.data;
            if (data) {
                const isJsonBlob = data instanceof Blob && data.type === "application/json";
                //@ts-ignore
                const responseData = isJsonBlob ? (yield data?.text()) : data || {};
                const responseJson = (typeof responseData === "string") ? JSON.parse(responseData) : responseData;
                const err = responseJson as APIErrorInterface;
                if (err?.faultcode?.endsWith('.access_denied')) {
                    toast.dismiss();
                    showErrorToast(i18n.t<string>('errors:common.noPermissionsToData'));
                }
            }
            action.payload.restrictedCallback && action.payload.restrictedCallback();
        }
        yield put(actions.getCallHistoryFileAsBlob.failure());
    }
}

export function* getCallHistoryDataForStatistics(
    action: ActionType<typeof actions.getCallHistoryDataForStatistics.request>,
) {
    try {
        // @ts-ignore
        const timezoneOffset = yield select((state: ReduxState) => state.generic.sessionData?.tz_offset) || 0;

        let accounts: ExtensionType[] | undefined = undefined;
        if (action.payload.extensionIds) {
            const accountsParams = {
                "has_extension": 1,
                "get_only_real_accounts": 1,
                "get_not_closed_accounts": 1,
                "get_status": 1,
                "limit_alias_did_number_list": 100
            };

            const notFilteredAccounts: ExtensionType[] = yield pagedDataRequest<ExtensionType>(Account.GetAccountList, accountsParams, (data) => data.account_list);
            accounts = notFilteredAccounts.filter(e => action.payload.extensionIds?.find(c => c === e.extension_id));
        }

        const date = dayjs.utc();
        const fromDate = date.utcOffset(timezoneOffset / 60).add(-1 * action.payload.timeRange, 'minute').format(dateFormat);
        const toDate = date.utcOffset(timezoneOffset / 60).format(dateFormat);
        const fromDateUserDateFormat = date.utcOffset(timezoneOffset / 60).add(-1 * action.payload.timeRange, 'minute').format(action.payload.userDateFormat);
        const toDateUserDateFormat = date.utcOffset(timezoneOffset / 60).format(action.payload.userDateFormat);

        const params = {
            show_unsuccessful: '1',
            i_service_type: '3',
            from_date: convertUserLocalTimeToUtc(fromDate, timezoneOffset),
            to_date: convertUserLocalTimeToUtc(toDate, timezoneOffset),
            limit: 10000,
            get_total: 1
        };

        const resCalls: AxiosResponse<{
            xdr_list: CallHistory[];
            total: number;
        }> = yield pagedAsyncDataRequest<WidgetCallHistory>(Customer.GetCustomerXDRS, params);

        // @ts-ignore
        let callHistoryItems: WidgetCallHistory[] = resCalls.response.xdr_list

        if (accounts) {
            callHistoryItems = callHistoryItems.filter(call => accounts?.find(e => e.i_account === call.i_account));
        }

        const formatTime = dateFormat.split(' ')[1];
        for (const call of callHistoryItems) {
            const startTime = dayjs.utc(call.connect_time ?? call.bill_time, dateFormat);
            const finishTime = dayjs.utc(call.disconnect_time ?? toDate, dateFormat);

            const diffDuration = dayjs.utc(finishTime.diff(startTime));
            call.callDuration = diffDuration.format(formatTime);

            const diffInSec = date.diff(startTime) / 1000;
            call.startedMinutesAgo = diffInSec / 60;
            call.durationInSec = finishTime.diff(startTime) / 1000;
        }

        yield put(
            actions.getCallHistoryDataForStatistics.success({
                // @ts-ignore
                callsTotal: resCalls.response.total,
                calls: callHistoryItems,
                lastDateFrom: fromDateUserDateFormat,
                lastDateTo: toDateUserDateFormat,
            })
        );
    } catch (err) {
        //@ts-ignore
        const error = err?.response?.data;
        yield put(actions.getCallHistoryDataForStatistics.failure(error));
    }
}

export function* getExtensionsListForCallsDoughnut(action: ActionType<typeof actions.getExtensionsListForCallsDoughnut.request>) {
    try {
        const resTotal: AxiosResponse<AccountListResponse> = yield fetchAccountList(undefined, {
            "has_extension": 1,
            "get_only_real_accounts": 1,
            "get_not_closed_accounts": 1,
            "limit": action?.payload?.limit || undefined,
            "useNewLogic": true
        }, action?.payload?.skipDid || false, action?.payload?.skipService || false);

        const data = resTotal.data?.account_list || [];
        yield put(actions.getExtensionsListForCallsDoughnut.success(data));

    } catch (err) {
        //@ts-ignore
        const error = err?.response?.data;
        yield put(actions.getExtensionsListForCallsDoughnut.failure(error));
    }
}

export function* checkIfTranscriptionExist(action: ActionType<typeof actions.checkIfTranscriptionExist.request>) {
    try {

        const {session_id, csrf_token} = yield select((state: ReduxState) => state.auth);
        const body = new JSONFormData(session_id, csrf_token);
        body.setParams({
            "call_recording_id": action.payload.callRecordingId,
            "check_only": 1
        });

        const res: AxiosResponse<{ success: boolean }> = yield api.post(
            CDR.GetTranscription,
            body,
        );

        if (res.data.success && action.payload?.autoFetch) {
            yield put(actions.fetchTranscription.request({callRecordingId: action.payload.callRecordingId}))
        }

        yield put(actions.checkIfTranscriptionExist.success({transcriptionExist: res.data.success}))

    } catch (err) {

        //@ts-ignore
        showErrorToast(err.response?.data?.faultstring);
        yield put(actions.checkIfTranscriptionExist.failure());
    }

}


export function* deleteTranscription(action: ActionType<typeof actions.deleteTranscription.request>) {
    try {

        const {session_id, csrf_token} = yield select((state: ReduxState) => state.auth);
        const body = new JSONFormData(session_id, csrf_token);
        body.setParams({
            "call_recording_id": action.payload.callRecordingId,
        });

        const res: AxiosResponse<{success:number}> = yield call(api.post, CDR.DeleteTranscription, body);

        if(action.payload.callback)
        {
            action.payload.callback()
        }

        if(res.data.success)
        {
            showErrorToast(i18n.t<string>('screens:calls.transcriptionDeleted'));
        }

        yield put(actions.deleteTranscription.success())

    } catch (err) {

        //@ts-ignore
        showErrorToast(err.response?.data?.faultstring);
        yield put(actions.deleteTranscription.failure());
    }

}

export function* fetchTranscription(action: ActionType<typeof actions.fetchTranscription.request>) {
    try {

        const {session_id, csrf_token} = yield select((state: ReduxState) => state.auth);
        const body = new JSONFormData(session_id, csrf_token);
        body.setParams({
            "call_recording_id": action.payload.callRecordingId,
        });

        const res: AxiosResponse<TranscriptionData> = yield call(api.post, CDR.GetTranscription, body);


        if (res.data.segments.length) {
            const parseDuration = (durationString: string) => {
                const [hours, minutes] = durationString.split(":").map(Number);
                return dayjs.duration({hours, minutes});
            };


            const transformTime = (timeString: string) => {
                const [hours, minutes] = timeString.split(":").map(Number);

                const seconds = hours;

                const formattedSeconds = String(seconds).padStart(2, "0");

                return `0:${formattedSeconds}`;
            };

            let startTime = dayjs().startOf("day");
            res.data.segments.forEach(el => {

                const dur = parseDuration(transformTime(el.segment.duration));
                const end = startTime.add(dur);
                el.segment.intervals = {start: startTime.format("HH:mm"), end: end.format("HH:mm")}

                startTime = end
            })

        }


        yield put(actions.fetchTranscription.success(res.data))

    } catch (err) {

        //@ts-ignore
        showErrorToast(err.response?.data?.faultstring);
        yield put(actions.fetchTranscription.failure());
    }

}


export function* downloadTranscriptionFile(action: ActionType<typeof actions.downloadTranscriptionFile.request>) {
    try {

        const {session_id, csrf_token} = yield select((state: ReduxState) => state.auth);
        const body = new JSONFormData(session_id, csrf_token);
        body.setParams({
            "call_recording_id": action.payload.callRecordingId,
        });

        const res: AxiosResponse = yield call(api.post, CDR.GetTranscription, body, {responseType: 'blob'});

        const contentType = res.headers['content-type'];

        if (contentType === 'application/zip') {
            const blob = new Blob([res.data], {type: contentType});
            download(blob, action.payload.callRecordingId, 'zip');
        } else if (contentType === 'application/json') {
            const reader = new FileReader();
            reader.onload = () => {
                const text = reader.result as string;
                const data = JSON.parse(text);
                const segments = data.segments;

                // @ts-ignore
                downloadToTxt(segments.map((el) => el.segment.text.trim()).join('\n\n'), action.payload.callRecordingId);
            };
            reader.readAsText(res.data);
        } else {
            throw new Error('Unsupported content type');
        }
        yield put(actions.downloadTranscriptionFile.success())

    } catch (err) {

        //@ts-ignore
        showErrorToast(err.response?.data?.faultstring);
        yield put(actions.downloadTranscriptionFile.failure());
    }

}

export function* updateCallRecordingSegment(action: ActionType<typeof actions.updateCallRecordingSegment.request>) {
    const {session_id, csrf_token} = yield select((state: ReduxState) => state.auth);
    const body = new JSONFormData(session_id, csrf_token);

    try {
        body.setParams({
            "call_recording_id": action.payload.call_recording_id,
            "segment_id": action.payload.segment_id,
            "text": action.payload.text,
        });

        const res: AxiosResponse = yield api.post(CDR.UpdateTranscriptionSegment, body);

        if (res.data.success) {
            action.payload.callback(true)
            const {transcription} = yield select((state: ReduxState) => state.calls);

            const temp = {
                ...transcription, segments: transcription.segments.map((el: Segment) => {
                    if (el.segment_id === action.payload.segment_id) {
                        return {...el, segment: {...el.segment, text: action.payload.text}}
                    }

                    return el
                })
            }

            yield put(actions.fetchTranscription.success(temp))
        }

    } catch (err) {
        // @ts-ignore
        showErrorToast(err?.response?.data?.faultstring);
        action.payload.callback(false)
    }
}

export const callsSaga = [
        takeLatest(
            actions.getExtensionCallHistory.request,
            getExtensionCallHistoryList,
        ),
        takeLatest(
            actions.getExtensionCallHistoryFile.request,
            getExtensionCallHistoryFile,
        ),
        takeLatest(actions.getCallHistory.request, getCallHistoryList),
        takeLatest(actions.removeCallRecordings.request, removeCallRecordings),
        takeLatest(
            actions.getCallHistoryBasicData.request,
            getCallHistoryBasicData,
        ),
        takeLatest(
            actions.getInitialCallHistoryBasicData.request,
            getInitialCallHistoryBasicData,
        ),
        takeLatest(
            actions.getSupervisorHeaderData.request,
            getSupervisorHeaderData,
        ),
        takeLatest(actions.getCallHistoryFileAsBlob.request, getCallHistoryFileAsBlob),
        takeLatest(actions.getCallHistoryDataForStatistics.request, getCallHistoryDataForStatistics),
        takeLatest(actions.getExtensionsListForCallsDoughnut.request, getExtensionsListForCallsDoughnut),
        takeLatest(actions.checkIfTranscriptionExist.request, checkIfTranscriptionExist),
        takeLatest(actions.downloadTranscriptionFile.request, downloadTranscriptionFile),
        takeLatest(actions.fetchTranscription.request, fetchTranscription),
        takeLatest(actions.updateCallRecordingSegment.request, updateCallRecordingSegment),
        takeLatest(actions.deleteTranscription.request, deleteTranscription)
    ]
;
