import classNames from "classnames";
import OverflowTooltip from "../components/OverflowTooltip/OverflowTooltip";
import React from "react";
import {ExtensionType} from "../store/types/Extension";
import {makeStyles} from "@material-ui/core";
import {Colors} from "../styles/Colors";
import {useTranslation} from "react-i18next";

interface ExtensionNameDisplayInterface {
    extension: ExtensionType
    darkMode: boolean
    dataQa: string

}

const useStyles = makeStyles(() => ({
    listViewNameText: {
        fontWeight: 400,
        fontSize: 16,
        lineHeight: '19px',
        color: Colors.Text2,
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        flex: 1,
        textAlign: 'left',
        minWidth: '30%',
    },
    listViewNameTextDarkMode: {
        color: '#F5F7F8'
    },
    noName: {
        opacity: 0.5
    },
}));

const ExtensionNameDisplay: React.VFC<ExtensionNameDisplayInterface> = ({extension, darkMode, dataQa}) => {

    const classes = useStyles()
    const {t} = useTranslation()

    return (
        <div
            className={classNames(classes.listViewNameText, !extension.extension_name?.length && classes.noName, darkMode && classes.listViewNameTextDarkMode)}>
            <OverflowTooltip
                tooltip={(extension.extension_name?.length ?? 0) > 0 ? extension.extension_name : t('common:unnamed')}
                text={(extension.extension_name?.length ?? 0) > 0 ? extension.extension_name : t('common:unnamed')}
                classes={{
                    text: classNames(classes.listViewNameText,
                        !extension.extension_name?.length && classes.noName,
                        darkMode && classes.listViewNameTextDarkMode
                    )
                }}
                dataQa={dataQa}
                copy={false}
            />
        </div>
    )
}


export default ExtensionNameDisplay