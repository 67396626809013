import React, { useCallback, useState } from "react";
import {ReactComponent as WhisperIcon} from '../../assets/call_control_icons/whisper.svg';
import {makeStyles} from "@material-ui/styles";
import {Colors} from "../../styles/Colors";
import {useTranslation} from "react-i18next";
import CustomizedTooltip from "../Tooltip/Tooltip";
import {AgentCallControlsProps} from "./callControls.utils";
import {useDispatch} from "react-redux";
import {actions} from "../../store";
import classNames from "classnames";
import {SipCallState} from "../../store/actions/ringgroups/payloads";
import {YesNo} from "../../store/types/CallScreening";
import { CircularProgress } from "@material-ui/core";

const useStyles = makeStyles(() => ({
    root: {
        '& path, & rect': {transition: 'fill .4s ease'},
        cursor: 'pointer',

    },

    icon: {
        '& > rect': {
            fill: Colors.White
        },
        '& >  path': {
            fill: Colors.Link
        },
        '&:hover > rect': {
            fill: Colors.Link
        },
        '&:hover  path': {
            fill: Colors.White
        },
    },

    isActiveIcon: {
        '& > rect': {
            fill: Colors.Primary2
        },
        '& path': {
            fill: Colors.White
        },
    },

    disabled: {
        '& > rect': {
            fill: 'transparent'
        },

        '& path': {
            fill: Colors.Gray6
        },
    },

    progress: {
        display: 'flex',
        justifyContent: 'center',
        zIndex: 999,
        flexDirection: 'column',
        width: '24px !important',
        height: '24px !important',
        marginLeft: 6,
        marginTop: 6,
        
        '& .MuiCircularProgress-svg': {
            color: Colors.White,
        },
    },

    activeButton: {
        height: 36,
        width: 36,
        borderRadius: 18, 
        background: Colors.Primary,
        marginRight: 10,
    }
}));

const Whisper: React.VFC<AgentCallControlsProps> = (
    {
        activeCallEntity,
        callbackAfterOnClick,
        isReadOnly,
        participantId,
        call,
        isActive,
        supervisorIsInCall,
        inCallParticipantId,
        conferenceName,
    }) => {

    const classes = useStyles();
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const [isInProgress, setIsInProgress] = useState(false);

    const disabled = isReadOnly;

    const onClick = useCallback(() => {
        if(isActive) {
            return;
        }
        if(isInProgress) {
            return;
        }
        if(supervisorIsInCall) {
            setIsInProgress(true);
            dispatch(actions.updateConferenceParticipant.request({
                participant_info: {
                    participant_id: inCallParticipantId ?? 0,
                    mute: YesNo.No,
                    coach: YesNo.Yes,
                },
                conference_info: {
                    name: conferenceName ?? '',
                    owner_id: participantId,
                },
                callback: () => { 
                    setIsInProgress(false);
                    callbackAfterOnClick?.();
                }
            }));
        }
        else {
            dispatch(actions.agentJoinToActiveCall.request({
                call_info: {id: call.id, tag: call.tag},
                participant_info: {
                    participant_id: participantId,
                    mute: YesNo.No,
                    coach: YesNo.Yes
                },
                conference_info: {
                    name: call.id,
                    owner_id: call.tag
                },
                callback: callbackAfterOnClick ? () => {
                    setTimeout(() => {
                        callbackAfterOnClick()
                    }, 500)
                } : undefined
            }));
        }
    }, [isActive, call, participantId, callbackAfterOnClick, setIsInProgress]);

    return (
        activeCallEntity.state == SipCallState.Holding ? <></> :
            <CustomizedTooltip
                title={disabled ? '' : t('screens:calls.whisper')}
                copy={false}
                disableHoverListener={isReadOnly}
            >
                {isInProgress ? (
                    <div className={classes.activeButton}>
                        <CircularProgress className={classNames(classes.progress)} />
                    </div>
                ) : (
                    <WhisperIcon
                        className={classNames(classes.root, 
                            disabled ? classes.disabled : classes.icon,
                            isActive && classes.isActiveIcon)}
                        onClick={!disabled ? onClick : undefined}
                    />
                )}
            </CustomizedTooltip>
    );
}

export default Whisper;