import React from "react";
import {makeStyles} from "@material-ui/styles";
import {Colors} from "../../styles/Colors";
import classNames from "classnames";
import {escapeRegExp} from "../TextTranscription/transcription.utils";

interface SearchableTextInterface {
    text: string,
    search?: string
    bold?: boolean,
    activeFoundIndex?: number
}


const useStyle = makeStyles(() => ({
    container: {
        maxWidth: '85%',
        overflowWrap: 'break-word',
        wordWrap: 'break-word',
        whiteSpace: 'normal',
        display:"block",
        flex:1,
        lineHeight: '19px',

    },
    marked: {
        color: Colors.White,
        backgroundColor: Colors.Primary,
        fontSize: 14,
        height:'fit-content'
    },

    activeMarked: {
        color: Colors.White,
        backgroundColor: Colors.LightGraySecondary
    },
    bold: {
        fontWeight: 500,
        color: Colors.Text

    }
}))

const SearchableText: React.VFC<SearchableTextInterface> = ({text, search, bold, activeFoundIndex}) => {

    const classes = useStyle()

    const highlightText = (text: string, search?: string) => {
        if (!search) {
            return text;
        }

        const regex = new RegExp(`(${escapeRegExp(search)})`, 'gi');
        const parts = text.split(regex);
        const filter = parts
            .map((item, index) => ({item, index}))
            .filter(obj => regex.test(obj.item));

        return parts.map((part, index) =>
            part.toLowerCase() === search.toLowerCase() ?
                (<span key={index}
                       className={classNames(classes.marked, ((activeFoundIndex !== -1 && activeFoundIndex !== undefined) && filter[activeFoundIndex] && filter[activeFoundIndex].index === index) && classes.activeMarked)}>
                    {part}
                </span>)
                : (
                    part
                )
        );
    };

    return <div className={classNames(bold && classes.bold, classes.container)}>{highlightText(text, search)}</div>
}

export default SearchableText