import {Grid} from "@material-ui/core";
import classNames from "classnames";
import InternalAudioPlayer from "../../AudioPlayer/InternalAudioPlayer";
import {Permission} from "../../../store/types/Permission";
import PermissionProvider from "../../PermissionProvider/PermissionProvider";
import CustomizedIconButton from "../../IconButton/IconButton";
import {CloudDownload, Delete} from "@material-ui/icons";
import React, {useMemo} from "react";
import {CallHistory} from "../../../store/types/CallHistory";
import {useStyles} from "./CallRecordInformationTabsDialog.utils";
import {useTranslation} from "react-i18next";
import {
    CallsStateType,
    PlayingFile,
    RecordingsPlayingStatus
} from "../../../store/reducers/calls/reducer";
import {useSelector} from "react-redux";
import {ReduxState} from "../../../store/types";
import InfoLayout from "../../../layouts/InfoLayout";

interface RecordingTabInterface {
    record?: CallHistory
    downloadPermission?: string[]
    onDownloadIconClick: () => void
    isDownloadingGoingOn?: boolean[]
    isOpen: boolean
    downloadTranscriptionPermission?: string[]
    deletePermission?: string[]
    onDeleteClick?: () => void
    filesQueue?: PlayingFile[][]

}

const RecordingTab: React.VFC<RecordingTabInterface> = (
    {
        onDeleteClick,
        record,
        deletePermission,
        downloadPermission,
        onDownloadIconClick,
        isDownloadingGoingOn,
        filesQueue
    }) => {

    const classes = useStyles()
    const {t} = useTranslation()

    const {internalRecordingsPlayer} = useSelector<ReduxState, CallsStateType>(
        (state) => state.calls
    );

    const audioFilePlaying = useMemo(() => {
        return !(!internalRecordingsPlayer || internalRecordingsPlayer.status === RecordingsPlayingStatus.notDefined
            || internalRecordingsPlayer.status === RecordingsPlayingStatus.dowloadingRestricted);
    }, [internalRecordingsPlayer]);

    return (
        <div className={classes.rootContainer}>
            {record?.cr_download_ids?.[0] ? (
            <Grid className={classNames(classes.inputsContainer)}>

                <Grid className={classNames(classes.recordingActionContainer)}>

                    <InternalAudioPlayer
                        dataQa={'audio-player-element'}
                        dataTestId={'audio-player-element'}
                        visible={audioFilePlaying}
                        downloadTranscriptionPermission={Permission.Calls.Recordings.PlayCallRecording.DownloadCallTranscription.value}
                        source={filesQueue}
                    >
                        <div className={classes.buttonsContainer}>

                            <PermissionProvider
                                permission={downloadPermission || []} skipReadOnly
                            >
                                <CustomizedIconButton
                                    tooltipText={t(
                                        'tooltips:ringGroups.downloadRecording',
                                    )}
                                    dataQa="details-modal-download-icon"
                                    dataTestId="details-modal-download-icon"
                                    onClick={onDownloadIconClick}
                                    disabled={
                                        !(
                                            (isDownloadingGoingOn &&
                                                !isDownloadingGoingOn[0]) ||
                                            !isDownloadingGoingOn
                                        )
                                    }
                                >
                                    <CloudDownload/>
                                </CustomizedIconButton>

                            </PermissionProvider>

                            <PermissionProvider
                                permission={deletePermission || []}
                            >
                                <CustomizedIconButton
                                    tooltipText={t('common:delete')}
                                    dataQa="details-modal-delete"
                                    dataTestId="details-modal-delete"
                                    onClick={onDeleteClick}
                                    className={classes.iconMargin}
                                >
                                    <Delete/>
                                </CustomizedIconButton>
                            </PermissionProvider>

                        </div>

                    </InternalAudioPlayer>


                </Grid>
            </Grid>
            ) : <InfoLayout>{t('screens:calls.noRecording')}</InfoLayout>}
        </div>

    )
}

export default RecordingTab