import React, {useState} from "react";
import SearchNavigator from "../components/SearchNavigator/SearchNavigator";
import CopyContentIcon from "../components/CopyContentIcon/CopyContentIcon";
import TranscriptionRow from "../components/TranscriptionRow/TranscriptionRow";
import {TranscriptionData} from "../store/reducers/calls/reducer";
import {escapeRegExp} from "../components/TextTranscription/transcription.utils";
import {useTranslation} from "react-i18next";
import {makeStyles} from "@material-ui/styles";
import {Colors} from "../styles/Colors";
import dayjs from "../services/customDayJs";

interface TextSearchBarInterface {
    transcription?: TranscriptionData
    callRecordingId?: string
    divMaxHeight?: number
    actionContainerCustomClass?: string
    rowContainerDetailsCustomClass?: string
    formatedPeriod: string
}

export const useStyle = makeStyles(() => ({
    greyIcon: {
        '& path': {
            fill: Colors.PlaceHolder
        },
        marginRight: 10
    },
}))


const TextSearchBar: React.FC<TextSearchBarInterface> = (
    {
        transcription,
        callRecordingId,
        divMaxHeight,
        actionContainerCustomClass,
        rowContainerDetailsCustomClass,
        formatedPeriod,
        children
    }) => {

    const {t} = useTranslation()

    const [searchText, setSearchText] = useState<string>('')
    const [matchIndex, setMatchIndex] = useState<number>(0)
    const [matchTotal, setMatchTotal] = useState<number>(0)
    const [matrix, setMatrix] = useState<number[][]>([])

    const classes = useStyle()

    const copyContent = () => {
        const data: string[] = []

        transcription?.segments.forEach(el => {
            data.push(el.segment.intervals?.start + '->' + el.segment.intervals?.end)
            data.push(el.segment.text)
            data.push('')

        })
        return data.join("\n");
    }

    const countMatchesInSegments = (search: string) => {

        if (!search.length) {
            return {
                matrix: [],
                counter: 0
            }
        }

        let counter = 0
        const matrix: number[][] = [];

        const regex = new RegExp(escapeRegExp(search), 'gi');

        transcription?.segments.forEach((el, index) => {
            const occ = el.segment.text.toLowerCase().match(regex)

            if (occ?.length) {
                matrix[index] = Array.from({length: occ?.length}, (_, i) => (counter + 1) + i)
            }
            counter += occ?.length || 0
        })


        return {
            matrix,
            counter
        }
    }

    const handleSearchString = (value: string) => {
        setSearchText(value)
        const matches = countMatchesInSegments(value)

        setMatrix(matches.matrix)
        setMatchIndex(0)
        setMatchTotal(matches.counter)
    }

    //will be used when we add segment tracking
    const isActive = (start: string, end: string, segmentTime: string) => {
        const startTime = dayjs(start, 'HH:mm');
        const endTime = dayjs(end, 'HH:mm');
        const check = dayjs(segmentTime, 'HH:mm');

        return check.isAfter(startTime) && check.isBefore(endTime) || check.isSame(endTime);
    }

    return (
        <>

            {children && (

                <div className={actionContainerCustomClass}>

                    {children}

                    <div>
                        <SearchNavigator customStyle={classes.greyIcon}
                                         onChangeValue={handleSearchString}
                                         total={matchTotal}
                                         matchIndex={matchIndex}
                                         clickOnDown={() => setMatchIndex(prevState => ++prevState)}
                                         clickOnUp={() => setMatchIndex(prevState => --prevState)}
                        />

                        <CopyContentIcon copyContent={copyContent()}
                                         tooltipMsg={t('tooltips:calls.allTranscription')}
                        />
                    </div>

                </div>
            )}

            {!children && (
                <div className={actionContainerCustomClass}>
                    <SearchNavigator customStyle={classes.greyIcon}
                                     onChangeValue={handleSearchString}
                                     total={matchTotal}
                                     matchIndex={matchIndex}
                                     clickOnDown={() => setMatchIndex(prevState => ++prevState)}
                                     clickOnUp={() => setMatchIndex(prevState => --prevState)}
                    />


                    <CopyContentIcon copyContent={copyContent()}
                                     tooltipMsg={t('tooltips:calls.allTranscription')}
                    />
                </div>
            )}


            <div className={rowContainerDetailsCustomClass}
                 style={{maxHeight: divMaxHeight ? divMaxHeight + 'px' : "unset"}}>

                {transcription && transcription.segments.map((el, index) =>
                    <TranscriptionRow
                        id={'interval-' + index.toString()}
                        key={'interval-' + index.toString()}
                        // @ts-ignore
                        matchIndex={matchIndex}
                        rowNumber={index}
                        duration={el.segment.duration}
                        text={el.segment.text.trim()}
                        // active={isActive(el.segment.intervals?.start || '', el.segment.intervals?.end || '', formatedPeriod)}
                        active={false}
                        start={el.segment.intervals?.start || ''}
                        end={el.segment.intervals?.end || ''}
                        search={searchText}
                        searchMatrix={matrix}
                        callSegment={callRecordingId ? {
                            segment_id: el.segment_id,
                            call_recording_id: callRecordingId
                        } : undefined}
                    />)
                }
            </div>
        </>
    )

}

export default TextSearchBar