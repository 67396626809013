import { ActionType, createReducer } from 'typesafe-actions';
import dayjs from '../../../services/customDayJs';
import * as actions from '../../actions';
import { ConferenceInfo, SipCall } from '../../actions/ringgroups/payloads';
import { APIErrorInterface } from '../../types';
import { ApiFile, SwitchModeApiFile } from '../../types/ApiFile';
import { ExtensionType } from '../../types/Extension';
import { RingGroupType } from '../../types/RingGroup';
import { ExtensionsListItem } from '../extensions/extensions/reducer';

export interface RingGroupsStateType {
    ringGroupsList: {
        total: number;
        items: RingGroupType[];
        filters: {
            name?: string;
            groupNumber?: string;
            extension?: string | number;
        };
    };
    huntGroupList?: RingGroupType[];
    responseMessageList?: SwitchModeApiFile[];
    isLoading: boolean;
    ringbackTonesList?: ApiFile[];
    onHoldList?: ApiFile[];
    isFormLoading?: boolean;
    groupMembersDetails?: {
        ringGroups: RingGroupType[];
        extensions: ExtensionsListItem[];
        extensionsWithVoicemail: ExtensionsListItem[];
    };
    errors?: APIErrorInterface;
    ringGroupDetails?: RingGroupType;
    isFormSending?: boolean;
    apiError?: APIErrorInterface;
    isLoadingSipCalls: boolean;
    sipCallsList?: SipCall[];
    sipCallsApiError?: APIErrorInterface;
    sipRingGroupsApiError?: APIErrorInterface;
    refreshTime?: string;
    isLoadingRingGroupsAccounts: boolean;
    accounts: ExtensionType[];
    serverTimeDifference: number;
    isLoadingConferenceList?: boolean;
    conferenceList?: ConferenceInfo[];
}

export const initialState: RingGroupsStateType = {
    ringGroupsList: {
        total: 0,
        items: [],
        filters: {},
    },
    isLoading: false,
    ringbackTonesList: [],
    isFormLoading: false,
    groupMembersDetails: {
        ringGroups: [],
        extensions: [],
        extensionsWithVoicemail: [],
    },
    isLoadingSipCalls: false,
    isLoadingRingGroupsAccounts: false,
    accounts: [],
    serverTimeDifference: 0,
    isLoadingConferenceList: false,
    conferenceList: [],
};

export type RingGroupsActionsType = ActionType<typeof actions>;

const ringGroupsReducer = createReducer<
    RingGroupsStateType,
    RingGroupsActionsType
>(initialState)
    .handleAction(actions.ringGroupsList.request, (state, action) => ({
        ...state,
        ringGroupsList: {
            ...state.ringGroupsList,
            filters: {
                name: action.payload.nameFilter,
                groupNumber: action.payload.groupNumberFilter,
                extension: action.payload.extensionFilter,
            },
        },
        isLoading: true,
        apiError: undefined
    }))
    .handleAction(actions.ringGroupsList.failure, (state) => ({
        ...state,
        isLoading: false,
    }))

    .handleAction(actions.ringGroupsList.success, (state, action) => {
        const ringGroupsList = action.payload;
        return {
            ...state,
            ringGroupsList: {
                ...state.ringGroupsList,
                ...ringGroupsList,
            },
            isLoading: false,
        };
    })
    .handleAction(actions.ringbackTonesList.success, (state, action) => {
        return {
            ...state,
            ringbackTonesList: action.payload,
        };
    })
    .handleAction(actions.onHoldList.success, (state, action) => {
        return {
            ...state,
            onHoldList: action.payload,
        };
    })
    .handleAction(actions.prepareDataForAddNewRingGroup.request, (state) => {
        return {
            ...state,
            isFormLoading: true,
            errors: undefined,
        };
    })
    .handleAction(actions.prepareDataForAddNewRingGroup.success, (state) => {
        return {
            ...state,
            isFormLoading: false,
        };
    })

    .handleAction(actions.groupMembersDetails.success, (state, action) => {
        return {
            ...state,
            groupMembersDetails: action.payload,
        };
    })
    .handleAction(actions.addRingGroup.request, (state) => {
        return {
            ...state,
            isFormSending: true,
        };
    })
    .handleAction(actions.addRingGroup.failure, (state, action) => {
        return {
            ...state,
            errors: action.payload,
            isFormSending: false,
        };
    })
    .handleAction(actions.ringGroupDetails.success, (state, action) => {
        return {
            ...state,
            ringGroupDetails: action.payload,
            apiError: undefined
        };
    })
    .handleAction(actions.ringGroupDetails.request, (state) => {
        return {
            ...state,
            isFormLoading: true
        };
    })
    .handleAction(actions.ringGroupDetails.failure, (state, action) => {
        return {
            ...state,
            isFormLoading: false,
            ringGroupDetails: undefined,
            apiError: state.apiError || action.payload
        };
    }).handleAction(actions.saveForms, (state)=>{
        return {
            ...state,
            errors:undefined
        };
    }).handleAction(actions.editRingGroup.request, (state) => {
        return {
            ...state,
            isFormSending: true,
        };
    })
    .handleAction(actions.editRingGroup.success, (state) => {
        return {
            ...state,
            isFormSending: false,
        };
    })
    .handleAction(actions.editRingGroup.failure, (state, action) => {
        return {
            ...state,
            isFormSending: false,
            errors: action.payload,
        };
    })
    .handleAction(
        actions.editRingGroupCallQueueStatus.success,
        (state, action) => {
            const ringGroupIndex = state.ringGroupsList.items.findIndex(
                (v) => v.i_c_group === action.payload.i_c_group,
            );

            const items = [...state.ringGroupsList.items];

            if (ringGroupIndex !== -1) {
                //@ts-ignore
                items[ringGroupIndex].assigned_callqueue = action.payload
                    .callQueueStatus
                    ? { i_c_queue: action.payload.i_c_queue }
                    : undefined;
            }

            return {
                ...state,
                ringGroupsList: {
                    ...state.ringGroupsList,
                    items,
                },
            };
        },
    )
    .handleAction(actions.createNewRingGroup.request, (state) => {
        return {
            ...state,
            isFormSending: true,
        };
    })
    .handleAction(actions.createNewRingGroup.failure, (state, action) => {
        return {
            ...state,
            errors: action.payload,
            isFormSending: false,
        };
    })
    .handleAction(actions.createNewRingGroup.success, (state) => {
        return {
            ...state,
            isFormSending: false,
        };
    })
    .handleAction(actions.uploadRingbackTone.request, (state) => {
        return {
            ...state,
            isLoading: true,
        };
    })
    .handleAction(actions.uploadRingbackTone.success, (state) => {
        return {
            ...state,
            isLoading: false,
        };
    })
    .handleAction(actions.uploadRingbackTone.failure, (state) => {
        return {
            ...state,
            isLoading: true,
        };
    })
    .handleAction(actions.getHuntGroupList.success, (state, action) => ({
        ...state,
        huntGroupList: action.payload,
    }))
    .handleAction(actions.onResponseMessageList.success, (state, action) => ({
        ...state,
        responseMessageList: action.payload,
    }))
    .handleAction(actions.getSipCallsList.request, (state) => ({
        ...state,
        sipCallsApiError: undefined,
        isLoadingSipCalls: true,
    }))
    .handleAction(actions.getSipCallsList.success, (state, action) => ({
        ...state,
        sipCallsList: action.payload,
        sipCallsApiError: undefined,
        isLoadingSipCalls: false,
        refreshTime: dayjs().format('YYYY-MM-DD HH:mm:ss')
    }))
    .handleAction(actions.getSipCallsList.failure, (state, action) => ({
        ...state,
        sipCallsApiError: action.payload,
        isLoadingSipCalls: false,
        refreshTime: undefined
    }))
    .handleAction(actions.updateSipCallStatusByWebSocketEvent.success, (state, action) => ({
        ...state,
        sipCallsList: action.payload
    }))
    .handleAction(actions.recalcSipCallsDuration.success, (state, action) => ({
        ...state,
        sipCallsList: action.payload
    }))
    .handleAction(actions.updateServerTimeDifference, (state, action) => ({
        ...state,
        serverTimeDifference: action.payload
    }))
    .handleAction(actions.getRingGroupsAccountList.request, (state) => ({
        ...state,
        sipRingGroupsApiError: undefined,
        isLoadingRingGroupsAccounts: true,
    }))
    .handleAction(actions.getRingGroupsAccountList.success, (state, action) => ({
        ...state,
        sipRingGroupsApiError: undefined,
        isLoadingRingGroupsAccounts: false,
        accounts: action.payload
    }))
    .handleAction(actions.getRingGroupsAccountList.failure, (state, action) => ({
        ...state,
        sipRingGroupsApiError: action.payload,
        isLoadingRingGroupsAccounts: false
    }))
    .handleAction(actions.getConferenceParticipantsList.request, (state) => ({
        ...state,
        isLoadingConferenceList: true,
    }))
    .handleAction(actions.getConferenceParticipantsList.success, (state, action) => { 
        const conferences = state.conferenceList ?? [];
        const indx = conferences.findIndex(e => e.name === action.payload.name);
        if(indx === -1)
        {
            conferences.push(action.payload);
        }
        else
        {
            conferences[indx] = action.payload;
        }
        return {
            ...state,
            isLoadingConferenceList: true,
            conferenceList: [...conferences]
        };
    })
    .handleAction(actions.getConferenceParticipantsList.failure, (state) => ({
        ...state,
        isLoadingConferenceList: true,
    }))
    .handleAction(actions.updateConferenceParticipant.success, (state, action) => { 
        const conferences = state.conferenceList ?? [];
        const indx = conferences.findIndex(e => e.name === action.payload.conference_info?.name);
        if(indx !== -1)
        {
            const participantIndex = conferences[indx]
                .participants
                ?.findIndex(e => e.participant_id == action.payload.participant_info?.participant_id) ?? -1;
            if(participantIndex !== -1)
            {   
                if(!conferences[indx].participants){
                    conferences[indx].participants = [];
                }
                conferences[indx].participants[participantIndex] = {
                    ...conferences[indx].participants[participantIndex],
                    mute: action.payload.participant_info.mute,
                    coach: action.payload.participant_info.coach
                };
                conferences[indx] = {
                    ...conferences[indx],
                };
            }
        }
        return {
            ...state,
            isLoadingConferenceList: false,
            conferenceList: [...conferences]
        };
    })
    ;
export default ringGroupsReducer;
