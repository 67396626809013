import {Grid} from "@material-ui/core";
import CustomizedTextField from "../../TextField/TextField";
import {roundUp} from "../../../utils/roundUp";
import React from "react";
import {useTranslation} from "react-i18next";
import {useStyles} from "./CallRecordInformationTabsDialog.utils";
import {convertUtcToUserLocalTime} from "../../../utils/dateWithTimezoneConversion";
import {CallHistory} from "../../../store/types/CallHistory";
import {GlobalCurrencyInfo} from "../../../store/types/CustomerInfo";


interface InfoTabInterface {
    customerCurrency?: string
    record?: CallHistory
    currencyInfo?: GlobalCurrencyInfo
    timezoneOffset: number
    userDateTimeFormat?: string
    callHistory?: CallHistory
}

const InfoTab: React.VFC<InfoTabInterface> = (
    {
        customerCurrency,
        record,
        currencyInfo,
        timezoneOffset,
        userDateTimeFormat,
        callHistory
    }
) => {

    const {t} = useTranslation()
    const classes = useStyles()


    const decimalDigits = currencyInfo?.decimal_digits || 2;

    const connectTime = record?.connect_time
        ? convertUtcToUserLocalTime(
            record.connect_time,
            timezoneOffset,
            userDateTimeFormat,
        )
        : '';
    const disconnectTime = record?.disconnect_time
        ? convertUtcToUserLocalTime(
            record.disconnect_time,
            timezoneOffset,
            userDateTimeFormat,
        )
        : '';
    const durationTime = new Date((record?.charged_quantity || 0) * 1000)
        .toISOString()
        .substr(11, 8);


    return (
        <>
            <Grid className={classes.inputsContainer}>
                <Grid className={classes.inputRow}>
                    <CustomizedTextField
                        id="connectTime"
                        label={t('screens:calls.connectTime')}
                        value={connectTime}
                        readOnly
                    />
                    <CustomizedTextField
                        id="disconnectTime"
                        label={t('screens:calls.disconnectTime')}
                        value={disconnectTime}
                        readOnly
                    />
                </Grid>
                <Grid className={classes.inputRow}>
                    <CustomizedTextField
                        id="duration"
                        label={t('screens:calls.duration')}
                        value={durationTime}
                        readOnly
                    />
                    <CustomizedTextField
                        id="disconnectReason"
                        label={t('screens:calls.disconnectReason')}
                        value={record?.disconnect_reason}
                        readOnly
                    />
                </Grid>
                <Grid className={classes.inputRow}>
                    <CustomizedTextField
                        id="country"
                        label={t('screens:calls.country')}
                        value={record?.country || t<string>('common:notSet')}
                        readOnly
                    />
                    <CustomizedTextField
                        id="area"
                        label={t('screens:calls.area')}
                        value={
                            record?.subdivision || t<string>('common:notSet')
                        }
                        readOnly
                    />
                </Grid>
                <Grid className={classes.inputRow}>
                    <CustomizedTextField
                        id="description"
                        label={t('screens:calls.description')}
                        value={callHistory?.description}
                        readOnly
                    />
                    <CustomizedTextField
                        id="charge"
                        label={`${t('screens:calls.charge')}, ${
                            currencyInfo?.iso_4217 || customerCurrency
                        }`}
                        value={roundUp(
                            record?.charged_amount || 0,
                            decimalDigits,
                        ).toFixed(decimalDigits)}
                        readOnly
                    />
                </Grid>
            </Grid>
        </>
    )
}

export default InfoTab