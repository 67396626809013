import React from 'react'
import {makeStyles} from "@material-ui/styles";
import {Colors} from "../../styles/Colors";
import classNames from "classnames";
import {ReactComponent as PlayActive} from '../../assets/play_active.svg';
import {getDurationFromSec} from "../../utils/transformers";

const useStyle = makeStyles(() => ({
    container: {
        display: "flex",
        color: Colors.Text3,
        fontSize: 12,
        fontWeight: 400,
        width: 80,
        gap: 2,
        height: 'fit-content'
    },
    active: {
        color: Colors.Primary,
    }
}))

interface TimeSlotInterface {
    time1: string,
    time2: string,
    active: boolean,
    duration?: string
}

const TimeSlot: React.VFC<TimeSlotInterface> = ({time2, time1, duration, active = false}) => {

    const classes = useStyle()

    return (
        <div className={classNames(classes.container, active && classes.active)}>
            {duration ? <span>{getDurationFromSec(parseFloat(duration)).string}</span> : (
                <>
                    <span>{time1}</span>
                    {active ? <span> <PlayActive/> </span> : <span> - </span>}
                    <span>{time2}</span>
                </>
            )}

        </div>
    )
}

export default TimeSlot