import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import {createStyles, makeStyles, PropTypes} from '@material-ui/core';
import {blackWithOpacity, Colors, greyColor} from '../../styles/Colors';
import classNames from 'classnames';
import LineLoader from "../LineLoader/LineLoader";
import Loader from "../Loader/Loader";

export enum SpinnerVariant {
    Line = 'Line',
    Circle = 'Circle',
}

export type DialogContainerProps = {
    isOpen: boolean;
    dataQa?: string;
    header?: string;
    customHeaderWidget?:JSX.Element
    dialogActionsButtons?:
        | ReturnType<typeof DialogButton>
        | ReturnType<typeof DialogButton>[];
    dialogLeftSideActionsButtons?:
        | ReturnType<typeof DialogButton>
        | ReturnType<typeof DialogButton>[];
    className?: string;
    headerClass?: string;
    contentClass?: string;
    dataTestId?: string;
    hideHeader?: boolean;
    disableEnforceFocus?: boolean;
    showLoader?: boolean;
    dialogVariant?: SpinnerVariant
};

export type DialogButtonPropsType = {
    onClick?: () => void;
    label: string | React.ReactNode;
    className?: string;
    color?: PropTypes.Color;
    disabled?: boolean;
    id?: string;
    dataTestId?: string;
    dataQa?: string;
    primary?: boolean;
    icon?: React.ReactNode;
    removeAccent?: boolean;
    secondaryAccent?: boolean;
};

const useStyles = makeStyles(() =>
    createStyles({
        circleSpinner: {
            position: "absolute",
            top: '50%',
            left: '50%'
        },
        extendHeader:{
          display:'flex',
          justifyContent:'space-between',
            alignItems:"center",
          '& #extendHeaderDiv2':{
              display:'flex',
              alignItems:'center',
              gap:'24px',
              '& div':{
                  width:'fit-content'
              }
          }
        },
        container: {
            //@ts-ignore
            zIndex: '1000!important',
            '& .MuiDialog-paperWidthSm': {
                maxWidth: 'unset',
            },
            '& .MuiDialogTitle-root': {
                padding: 0,
            },
            '& h2': {
                borderBottom: `1px solid ${blackWithOpacity}`,
                padding: 20,
                fontSize: 18,
            },
            '& .MuiDialogContent-root': {
                padding: 32,
                minWidth: 500,
                '& .MuiDialogContentText-root': {
                    marginBottom: 0,
                    color: 'black',
                },
            },
            '& .medium-width-modal-delete': {
                minWidth: '336px !important',
            },
            '& .MuiDialogActions-root': {
                borderTop: `1px solid ${blackWithOpacity}`,
                color: greyColor,
                padding: '11px 16px',
                justifyContent: 'space-between',

                '& > :first-child': {
                    marginRight: 0,
                },

                '&.MuiDialogActions-spacing > :not(:first-child)': {
                    marginLeft: 12,
                },
            },
            '& .alert-content': {
                '& p': {
                    whiteSpace: 'pre-line',
                },
            },
        },
        buttonsInsideContainer: {
            '& > :not(:first-child)': {
                marginLeft: 12,
            },
        },

        header: {
            ' & h2': {
                fontWeight: 700,
                fontSize: 18,
                padding: '24px 24px 17px 24px',
                lineHeight: '20px',
            },
        },

        dialogButton: {
            minWidth: 90,
            padding: '10px 8px',

            '& .MuiButton-label': {
                fontSize: 16,
                lineHeight: '16px',
                fontWeight: 'bold',
                color: Colors.Primary,
                textTransform: 'none',
            },

            '&.MuiButton-root.Mui-disabled': {
                backgroundColor: Colors.Gray11,
                '& .MuiButton-label': {
                    color: Colors.Gray10,
                },
            },

            '&:hover': {
                background: `${Colors.Primary1}!important`,
            },

            '& .MuiTouchRipple-root': {
                color: `${Colors.Primary}!important`,
            },
        },
        removeAccent: {
            '& .MuiButton-label': {
                color: Colors.Gray5,
            },

            '&:hover': {
                background: `${Colors.Gray8}!important`,
            },

            '& .MuiTouchRipple-root': {
                color: `${Colors.Gray5}!important`,
            },
        },
        secondaryAccent: {
            '& .MuiButton-label': {
                color: Colors.Secondary1,
            },

            '&:hover': {
                background: `${Colors.Secondary6}!important`,
            },

            '& .MuiTouchRipple-root': {
                color: `${Colors.Secondary6}!important`,
            },
        },

        primary: {
            '&.MuiButton-root': {
                backgroundColor: Colors.Primary,
                '& .MuiButton-label': {
                    color: Colors.White,
                },

                '&:hover': {
                    backgroundColor: Colors.Primary + '!important',
                    opacity: '0.88',
                },
            },
        },
    }),
);

export const DialogButton: React.VFC<DialogButtonPropsType> = (
    {
        onClick,
        label,
        className,
        color,
        disabled,
        id,
        dataTestId,
        dataQa,
        icon,
        removeAccent,
        secondaryAccent,
        primary,
    }) => {

    const classes = useStyles();

    return (
        <Button
            className={classNames(
                classes.dialogButton,
                primary && classes.primary,
                removeAccent && classes.removeAccent,
                secondaryAccent && classes.secondaryAccent,
                className,
            )}
            onClick={onClick}
            color={color}
            disabled={disabled}
            id={id}
            data-testid={dataTestId}
            data-qa={dataQa}
        >
            {icon}
            {label}
        </Button>
    );
};

const DialogContainer: React.FC<DialogContainerProps> = (
    {
        isOpen,
        dataQa,
        header,
        dialogActionsButtons,
        dialogLeftSideActionsButtons,
        children,
        className,
        headerClass,
        contentClass,
        dataTestId,
        hideHeader = false,
        disableEnforceFocus,
        showLoader,
        dialogVariant = SpinnerVariant.Circle,
        customHeaderWidget
    }) => {

    const classes = useStyles();

    return (
        <Dialog
            open={isOpen}
            data-qa={dataQa}
            className={classNames(classes.container, className)}
            data-testid={dataTestId || 'modal-container'}
            disableScrollLock
            disableEnforceFocus={disableEnforceFocus}
        >
            {!hideHeader && !customHeaderWidget && (
                <DialogTitle
                    className={classNames(classes.header, headerClass)}
                >
                        {header}

                </DialogTitle>
            )}


            {!hideHeader && customHeaderWidget && (
                <DialogTitle
                    className={classNames(classes.header, headerClass)}
                >
                    <div className={classes.extendHeader}>
                        <div id={'extendHeaderDiv1'}>{header}</div>
                        <div id={'extendHeaderDiv2'}>{customHeaderWidget}</div>
                    </div>

                </DialogTitle>
            )}

            {dialogVariant == SpinnerVariant.Line ? (<LineLoader show={showLoader}/>) : showLoader ?
                <Loader customClass={classes.circleSpinner} dataQa={'abc'}/> : <></>}

            <DialogContent data-testid="modal-content" className={contentClass}>
                {children}
            </DialogContent>

            {!!dialogActionsButtons && (
                <DialogActions>
                    <div className={classes.buttonsInsideContainer}>
                        {dialogLeftSideActionsButtons}
                    </div>
                    <div className={classes.buttonsInsideContainer}>
                        {dialogActionsButtons}
                    </div>
                </DialogActions>
            )}
        </Dialog>
    );
};

export default DialogContainer;
