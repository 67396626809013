import React, {useEffect, useMemo, useState} from "react";
import {ReactComponent as SearchIcon} from '../../assets/search.svg';
import {ReactComponent as ArrowDown} from '../../assets/chevron up.svg';
import {ReactComponent as ArrowUp} from '../../assets/chevron down.svg';
import {ReactComponent as Close} from '../../assets/close.svg';
import classNames from "classnames";
import {makeStyles} from "@material-ui/styles";
import {Colors} from "../../styles/Colors";

interface SearchNavigatorInterface {
    customStyle?: string,
    onChangeValue: (val: string) => void
    total?: number,
    matchIndex?: number,
    clickOnUp?: () => void
    clickOnDown?: () => void
}


const useStyle = makeStyles(() => ({

    searchResultContainer: {
        fontSize: 12,
        color: Colors.LightGraySecondary2,
        lineHeight: '16px',
        marginRight: 6
    },

    actionContainer: {
        border: 'none',
        borderLeftStyle: 'solid',
        borderColor: Colors.Gray3,
        borderLeftWidth: '1px',
        display: "flex",
        alignItems: 'center'
    },

    searchNavigatorContainer: {
        position: 'relative',
        display: "flex",
        alignItems: "center",

        '&:hover #search-text-placeholder': {
            width: 268,
            backgroundColor: Colors.LightGray2,
            opacity: 1,
            color: Colors.Gray7,
            marginRight:0,
        },

        '& #search-text-placeholder': {
            width: 0,
            marginRight:20,
            backgroundColor: 'transparent',
            opacity: 0,
            transition: 'all 0.3s ease',
            borderRadius: '5px'
        },

        '& > svg': {
            position: 'absolute',
            right: 0,
        },

        '& > div > svg': {
            position: 'initial',
            right: 'initial'
        }
    },

    placeholderContainer: {
        padding: 5,
        paddingRight: 10,
        fontSize: 12,
    },


    searchContainer: {

        border: 1,
        borderStyle: "solid",
        borderRadius: 5,
        borderColor: Colors.Gray3,
        padding: 8,
        display: 'flex',
        backgroundColor: Colors.White,
        alignItems: 'center',
        minWidth: 300,
        maxWidth: 300,

        '& input': {
            border: 'none',
            width: '100%',
            flex: 1
        },
        '& input:focus': {
            border: 'none',

        },
        '& input:focus-visible': {
            outline: 'none',

        }

    },

    blue: {
        '& path': {
            fill: Colors.Link + '!important'
        }
    },

    black: {
        width: 20,
        height: 20,
        '& path': {
            fill: Colors.BlackSurface + '!important'
        }
    },

    active: {
        '& path': {
            fill: Colors.BlackSurface + '!important'
        }
    },

    disabled: {
        '& path': {
            fill: Colors.PlaceHolder + '!important'
        }
    }

}))

const SearchNavigator: React.VFC<SearchNavigatorInterface> = (
    {
        clickOnDown,
        clickOnUp,
        customStyle,
        onChangeValue,
        total = 0,
        matchIndex = 0
    }) => {

    const classes = useStyle()

    const [isActive, setIsActive] = useState<boolean>(false)
    const [value, setValue] = useState<string>('')


    const activeDown = useMemo(() => {
        return matchIndex < total && matchIndex >= 0
    }, [total, matchIndex])

    const activeUp = useMemo(() => {
        return matchIndex <= total && matchIndex > 0
    }, [total, matchIndex])


    const resetState = () => {
        setIsActive(false)
        setValue('')
    }

    useEffect(() => {
        onChangeValue(value)
    }, [value]);

    return (
        <div className={classNames(customStyle ?? '', classes.searchNavigatorContainer)}
             onClick={() => !isActive ? setIsActive(prev => !prev) : null}>


            {!isActive && <SearchIcon onClick={() => isActive ? setIsActive(prev => !prev) : null}/>}


            {!isActive && (
                <div id={'search-text-placeholder'} className={classes.placeholderContainer}>
                    Search...
                </div>
            )}

            {isActive && (
                <div className={classes.searchContainer}>

                    <SearchIcon className={classes.blue}/>

                    <input type={'text'} value={value} onChange={(event) => setValue(event.currentTarget.value)}
                           onInput={(event) => setValue(event.currentTarget.value)}
                           autoFocus={true}
                    />

                    {!!total && (
                        <div className={classes.searchResultContainer}>
                            {matchIndex} / {total}
                        </div>
                    )}

                    <div className={classes.actionContainer}>

                        <ArrowDown onClick={activeDown ? clickOnDown : undefined}
                                   className={activeDown ? classes.active : classes.disabled}/>
                        <ArrowUp onClick={activeUp ? clickOnUp : undefined}
                                 className={activeUp ? classes.active : classes.disabled}/>

                        <Close className={classes.black} onClick={resetState}/>

                    </div>


                </div>
            )}


        </div>
    )
}
export default SearchNavigator