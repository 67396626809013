import {makeStyles} from "@material-ui/core";
import {Colors} from "../../../styles/Colors";
import {CallHistory} from "../../../store/types/CallHistory";
import {PlayingFile} from "../../../store/reducers/calls/reducer";

export interface CallRecordInformationTabsDialogProps {
    isOpen: boolean;
    toggleVisibility?: () => void;
    callHistory?: CallHistory;
    customerCurrency?: string;
    timezoneOffset: number;
    onDeleteClick?: () => void;
    onDownloadClick?: (i_xdr: number, call_recording_id: string) => void;
    userDateTimeFormat?: string;
    isDownloadingGoingOn?: boolean[];
    downloadPermission?: string[];
    deletePermission?: string[];
    downloadTranscriptionPermission?: string[];
    filesQueue?: PlayingFile[][]

}

export const useStyles = makeStyles(() => ({

    noBold:{
      fontWeight:'normal'
    },

    noOverflow:{
        overflow:'unset'
    },

    rootContainer:{
      display:"flex",
      height:'100%',
      flex:1,
        width:'100%'
    },

    recordingActionContainer: {
        display: 'flex'
    },

    tabContainer: {
        paddingLeft: 0,
        paddingRight: 0,
        '& header': {
            backgroundColor: 'transparent',
            padding: '0px',
            marginLeft: '20px',
            marginRight: '20px',
            width: 'auto',

            '& button': {
                gap: '10px',
                padding: '2px 16px 0px 16px !important',
                height: '46',
                minWidth: '120px !important'

            },

            '& + div > div': {
                padding: '0px !important',
                margin: '0 8px'
            },
            '& + div + div > div': {
                padding: '0px !important',
                margin: '0 8px'
            }
        },
    },
    primaryActionButton: {
        '& .MuiButton-label': {
            color: Colors.Primary,
        },
    },
    header: {
        '& .MuiTypography-h6': {
            fontWeight: 'bold',
            fontSize: 18,
        },
    },
    modalContainer: {
        '& .MuiDialogContent-root': {
            width: 900,
            backgroundColor: Colors.SmokeBackground,
            minHeight: '546px'
        },
    },
    inputsContainer: {
        padding: '0 12px 0 12px',
        display: 'flex',
        width:'100%',
        flexDirection: 'column',
        '& .MuiInputBase-root': {
            background: Colors.SmokeBackground,
        },
    },
    inputRow: {
        display: 'flex',
        marginTop: 22,

        '& .MuiFormControl-root': {
            flex: 1,
            margin: '0 6px',
        },
    },
    iconMargin: {
        marginLeft: 10,
    },
    buttonsContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: "end",
        width: 'fit-content'
    },
    tooltipContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '4px 8px',
    },
    tooltip: {
        display: 'flex',
        flex: 1,
        alignItems: 'center',
    },
    tooltipTitle: {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        fontSize: '16px',
        lineHeight: '28px',
        marginRight: '8px',
        whiteSpace: 'pre-wrap',
    },
    container: {
        '& .MuiPaper-root': {
            width: 336,
        },

        '& .MuiDialogContent-root': {
            minWidth: 'unset !important',
            padding: '24px 24px 26px 24px !important',
            background: `${Colors.White} !important`,
        },
        '& .MuiDialogActions-root': {
            '& > :first-child': {
                marginRight: 20,
            },
        },
    },
    shorterInput: {
        maxWidth: 330,
    },

    cloud: {
        display: 'flex',
        alignItems: 'center',
        color: Colors.LightGraySecondary3,
        marginLeft: 23,
        marginRight: 12,
    },
}));