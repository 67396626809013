import {ExtensionType} from "../../store/types/Extension";
import {Colors} from "../../styles/Colors";
import {SipCall, SipCallState, SipCallType, UpdatedRingGroupMember} from "../../store/actions/ringgroups/payloads";
import i18n from "../../services/i18n";

const isOnCallState = (state: SipCallState, type: SipCallType) => {
    return (state === SipCallState.Connected)
        || (state === SipCallState.Trying && type === SipCallType.Outgoing)
        || (state === SipCallState.Ringing && type === SipCallType.Outgoing)
        || (state === SipCallState.Queued && type === SipCallType.Outgoing)
        || (state === SipCallState.Dequeued)
}

const isOnHoldState = (state: SipCallState) => {
    return (state === SipCallState.Holding || state === SipCallState.Held)

}

const isOnRingingState = (state: SipCallState, type: SipCallType) => {
    return (state === SipCallState.Ringing && type === SipCallType.Incoming)
        || (state === SipCallState.Trying && type === SipCallType.Incoming)
}

const isParkedState = (state: SipCallState) => {
    return (state === SipCallState.Parked);
}

export const getStatus = (extension: ExtensionType, calls: SipCall[]) => {

    let statusText = i18n.t<string>('screens:wallboard.unknown')
    let statusColor = undefined;
    let callType = undefined;
    let duration = undefined;
    let isOnHold = false;
    let isOnCall = false;
    let isOnRinging = false;
    let isIdle = false;
    let isUnregistered = false;
    let isParked = false;

    const onCallIncome = calls.find(e => (
            e.callee?.extension_id === extension?.extension_id) &&
        isOnCallState(e.state, e.type)
    );

    const onCallOutgoing = calls.find(e => (
            e.caller?.extension_id == extension?.extension_id) &&
        isOnCallState(e.state, e.type)
    );

    const onHoldIn = calls.find(e => (e.callee?.extension_id === extension?.extension_id) &&
        isOnHoldState(e.state));

    const onHoldOut = calls.find(e => (e.caller?.extension_id == extension?.extension_id) &&
        isOnHoldState(e.state));

    const onRinging = calls.find(e => (
            e.callee?.extension_id === extension?.extension_id) &&
        isOnRingingState(e.state, e.type)
    );

    const onParked = calls.find(e => (
        (e.caller?.extension_id === extension?.extension_id && e.type === SipCallType.Outgoing)
        || (e.callee?.extension_id === extension?.extension_id && e.type === SipCallType.Incoming)
    ) && isParkedState(e.state) && extension.sip_status != 0);

    if (onCallIncome || onCallOutgoing) {
        statusText = i18n.t<string>('screens:wallboard.onCallCount');
        statusColor = Colors.Error;
        callType = onCallIncome?.type ? SipCallType.Incoming : SipCallType.Outgoing;
        duration = onCallIncome?.duration || onCallOutgoing?.duration;
        isOnCall = true;
    } else if (onHoldIn || onHoldOut) {
        statusText = i18n.t<string>('screens:wallboard.onHoldCount');
        statusColor = Colors.Primary2;
        callType = onHoldIn?.type ? SipCallType.Incoming : SipCallType.Outgoing;
        duration = onHoldIn?.duration || onHoldOut?.duration;
        isOnHold = true;
    } else if (onRinging) {
        statusText = i18n.t<string>('screens:wallboard.ringingCount');
        statusColor = Colors.Support;
        callType = onRinging.type;
        duration = onRinging.duration;
        isOnRinging = true;
    } else if(onParked) {
        statusText = i18n.t<string>('screens:wallboard.parkedCounts');
        statusColor = Colors.Error;
        callType = onParked?.type ? SipCallType.Incoming : SipCallType.Outgoing;
        duration = onParked?.duration;
        isParked = true;
    }
    else {
        switch (extension?.sip_status) {
            case 1:
                // statusText = 'Idle'
                statusText = i18n.t<string>('screens:wallboard.idleCounts');
                statusColor = Colors.Support;
                isIdle = true;
                break;
            case 0:
                // statusText = 'Unregistered'
                statusText = i18n.t<string>('screens:wallboard.unregisteredCount');
                statusColor = Colors.Text3;
                isUnregistered = true;
                break;
        }
    }

    return {
        extension,
        text: statusText,
        color: statusColor,
        callType,
        duration,
        isOnHold,
        isOnCall,
        isOnRinging,
        isIdle,
        isUnregistered,
        isParked,
    };
}

export interface AgentsTypeData {
    id: string,
    groupName?: string,
    show?: boolean,
    isGroup: boolean,
    i_c_group?: number,
    link?: string,
    extension?: string,
    name?: string,
    custom_id?: string,
    status?: {
        text: string,
        color?: string,
        callType?: SipCallType,
        duration?: string
        isOnHold: boolean
    },
    duration?: string,
    isLogged?: boolean,
    updatedRingGroupMember?: UpdatedRingGroupMember,
    showIcon: boolean
}