import React, {useEffect, useState} from "react";
import TranscriptionRow from "../TranscriptionRow/TranscriptionRow";
import classNames from "classnames";
import CallerInfo from "../Calls/CallerInfo";
import CustomizedIconButton from "../IconButton/IconButton";
import {ReactComponent as ExpandLess} from '../../assets/expand less.svg';
import {ReactComponent as ExpandMore} from '../../assets/expand more.svg';
import {ReactComponent as DirectionRight} from '../../assets/direction-right.svg';
import {useDispatch, useSelector} from "react-redux";
import {ReduxState} from "../../store/types";
import Loader from "../Loader/Loader";
import {CallsStateType} from "../../store/reducers/calls/reducer";
import {TextTranscriptionInterface, useStyle} from "./TextTranscription.utils";
import {useTranslation} from "react-i18next";
import {CallHistory} from "../../store/types/CallHistory";
import TextSearchBar from "../../layouts/TextSearchBar";
import dayjs from "dayjs";
import InfoLayout from "../../layouts/InfoLayout";


const TextTranscription: React.VFC<TextTranscriptionInterface> = (
    {
        detailsVisible,
        open,
        onClickExpand,
        callRecordingId,
        formatedPeriod,
        divMaxHeight = 144,
        iXdr
    }) => {

    const classes = useStyle();
    const dispatch = useDispatch();
    const {t} = useTranslation()

    const {transcription, fetchTranscription} = useSelector<ReduxState, CallsStateType>(
        (state) => state.calls
    );

    const items = useSelector<ReduxState, CallHistory[]>(
        (state) => state.calls.callHistory.items
    );
    const data = items.find(el => el.i_xdr === iXdr)

    // useEffect(() => {
    //
    //     if (callRecordingId && detailsVisible) {
    //         dispatch(actions.fetchTranscription.request({callRecordingId: callRecordingId}))
    //     }
    //
    // }, [detailsVisible, callRecordingId]);

    if (fetchTranscription && detailsVisible) {
        return (
            <div style={{display: "flex", alignSelf: 'flex-start', alignContent: 'center'}}>
                <Loader
                    dataQa={'fetch-transcription'}
                    text={t('common:processing') + '...'}
                    customClass={classes.loading}
                />
            </div>
        )
    }


    const isActive = (start: string, end: string, segmentTime: string) => {
        const startTime = dayjs(start, 'HH:mm');
        const endTime = dayjs(end, 'HH:mm');
        const check = dayjs(segmentTime, 'HH:mm');

        return check.isAfter(startTime) && check.isBefore(endTime) || check.isSame(endTime);
    }

    return detailsVisible ? (
        <div className={classes.transcriptionContainer}>

            {transcription && <TextSearchBar
                formatedPeriod={formatedPeriod} transcription={transcription}
                callRecordingId={callRecordingId} divMaxHeight={divMaxHeight}
                actionContainerCustomClass={classes.actionContainer}
                rowContainerDetailsCustomClass={classes.transcriptionContainerDetails}
            />}

            {!transcription && <InfoLayout>{t('screens:calls.noTranscription')}</InfoLayout>}

            <div className={classNames(classes.expandContainer, open && classes.expandContainerShow)}>

                {data && (
                    <div className={classes.expandableMembers}>
                        <CallerInfo caller={data.CLD || ''} className={classes.unsetFlex}/>
                        <DirectionRight className={classes.extraMargin}/>
                        <CallerInfo
                            caller={data.CLI || ''}
                            className={classes.unsetFlex}
                        />
                    </div>
                )}

            </div>

            {open ? (
                <CustomizedIconButton
                    onClick={() => {
                        onClickExpand()
                    }}
                    className={classes.expandIcon}
                    dataTestId={'expand-transcription-more'}
                    skipPermission
                    above
                >
                    <ExpandMore/>
                </CustomizedIconButton>
            ) : (
                <CustomizedIconButton
                    onClick={() => {
                        onClickExpand()
                    }}
                    className={classes.expandIcon}
                    dataTestId={'expand-transcription-less'}
                    skipPermission
                    above
                >
                    <ExpandLess/>
                </CustomizedIconButton>
            )}

        </div>
    ) : <></>

}

export default TextTranscription