import {ExtensionType} from "../../../store/types/Extension";
import {WidgetCallVolumesTimeRange} from "../../../store/types/Wallboard";
import {makeStyles} from "@material-ui/core";
import {Colors} from "../../../styles/Colors";

export interface LazyCallVolumesWidgetListViewItemInterface {
    extension: ExtensionType;
    numberOfCalls: boolean;
    darkMode: boolean;
    id?: string;
    period?: WidgetCallVolumesTimeRange;
    isLoadingChart?: boolean;
    hideWhenNoCalls?: boolean;
}

export const calcExtNameFontSize = (name: string) => {
    if (name.length <= 6) return 20 - ((name.length - 1) * 2);
    if (name.length <= 10) return 9 - (name.length - 7);
    return '6px';
};

export const useStyles = makeStyles(() => ({

    hide: {
        display: 'none !important'
    },
    listViewRow: {
        width: 'calc(100% - 16px)',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        height: 44,
        alignItems: 'center',
    },
    listViewRowSeparator: {
        display: 'block',
        left: 0,
        bottom: 0,
        height: 1,
        width: 'calc(100% - 40px - 16px)',
        marginLeft: 40,
        borderBottom: '1px solid rgba(0, 0, 0, 0.08)',
    },
    listViewIconText: {
        height: 'fit-content',
        width: '100%',
        fontWeight: 700,
        fontSize: 14,
        color: Colors.Text,
        marginTop: 'calc(50% - 14px)',
    },
    listViewIconTextDarkMode: {
        color: '#F5F7F8'
    },
    listViewIconContainer: {
        marginRight: 14,
        width: 40,
        height: 40,
        display: 'flex',
        justifyContent: 'center',
        borderRadius: 20,
        backgroundColor: Colors.Secondary11,
        marginTop: 2,
        minWidth: 40,
    },
    listViewIconContainerDarkMode: {
        backgroundColor: '#494A55'
    },
    listViewDurationText: {
        fontWeight: 400,
        fontSize: 14,
        lineHeight: '16px',
        color: Colors.Text,
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
    },
    listViewDurationTextDarkMode: {
        color: '#DDE1E7',
        fontSize: 13
    },
    callsCounter: {
        marginLeft: 10,
        fontWeight: 700,
        fontSize: 14,
        lineHeight: '16px',
        color: Colors.Text,
        overflow: 'hidden',
        whiteSpace: 'nowrap'
    },
    callsCounterDarkMode: {
        color: '#F5F7F8'
    },
}));